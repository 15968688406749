import React, { PureComponent } from 'react';
import {Form} from "antd";

//import '@ant-design/compatible/assets/index.css';


import { Switch, Tooltip } from 'antd';
import { FormContext } from "../context";
//import { Tooltip } from 'components/tooltip';

export default class FormSwitch extends PureComponent {
    static contextType = FormContext;

    render() {
        const { name, label, checked, className, onClick, disabled, tip, colon } = this.props;

        return (<Form.Item colon={colon}

            label={tip ? <Tooltip title={tip}>
                {' '}
                <span>
                    {' '}
                    {label}
                </span>
            </Tooltip> : label}


            className={`form-switch ${className || ''}`}
            name={name}
            valuePropName='checked'
            initialValue={checked}
            
            >
        <Switch name={name} onClick={onClick} disabled={disabled}>{label}</Switch>
        </Form.Item>);
    }
}