import { message } from 'antd';
import appAction from '../reduxlocal/actions/appAction';

//High order method for tabular data acquisition
export function tableHoc(func) {
    return function get(filter, sort, pagination, ...other) {
        return async (dispatch) => {
            dispatch(appAction.loading());
            try {
                const { res, actionType, ...otherProps } = await func(
                    filter,
                    sort,
                    pagination,
                    ...other
                );
                const { recordsTotal, data } = res;

                const { pageSize, current } = pagination;
                if (data.length === 0 && current > 1) {
                    return dispatch(
                        get(filter, sort, {
                            ...pagination,
                            total: recordsTotal,
                            current: Math.ceil(recordsTotal / pageSize),
                        })
                    );
                }

                dispatch({
                    type: actionType,
                    ...otherProps,
                    data: {
                        data: data,
                        filter,
                        sort,
                        pagination: {
                            ...pagination,
                            total: recordsTotal,
                        },
                    },
                });
                return res;
            } catch (e) {
                console.log(e);
                if (e.message) {
                    if (e.message.includes('login again')) {
                        message.error(e.message);
                    }
                    //notification.error({
                    //    message: 'loading fail',
                    //    description:
                    //        'An error occurred while requesting data.',
                    //});
                    throw e;
                }
            }
            dispatch(appAction.loaded());
        };
    };
}
