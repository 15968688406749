import React, { PureComponent } from "react";
import {Form} from "antd";

//import '@ant-design/compatible/assets/index.css';


import { DatePicker } from 'antd';
import { FormContext } from "../context";

export default class FormDatePicker extends PureComponent {
    static contextType = FormContext;

    render() {
        const {
            value,
            name,
            rules,
            label,
            placeholder,
            disabledDate
        } = this.props;
        // const [form] = Form.useForm();


        return (
            <Form.Item name={name} rules={rules} initialValue={value} label={label} colon={false} className="from-date-picker">
                <DatePicker
                        placeholder={placeholder}
                        name={name}
                        disabledDate={disabledDate}
                    >
                    </DatePicker>,
                
            </Form.Item>
        );
    }
}
