import React, { PureComponent } from 'react';
import {Form} from "antd";

// import '@ant-design/compatible/assets/index.css';


import { Checkbox } from 'antd';
import { FormContext } from "../context";

export default class FormCheckbox extends PureComponent {
    static contextType = FormContext;

    render() {
        const { name, label, checked, onClick, rules, id } = this.props;
        // const [form] = Form.useForm();

        return (
            <Form.Item name={name} initialValue={checked} valuePropName='checked' rules={rules} className={`form-checkbox`} id={id || ("form_item_" + name)}>
                <Checkbox name={name} onClick={onClick}>{label}</Checkbox>
            </Form.Item>
        );
    }
}