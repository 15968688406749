import React, { PureComponent } from 'react';
import {Form} from "antd";

//import '@ant-design/compatible/assets/index.css';


import { Input } from 'antd';
import { FormContext } from '../context';

export default class FormInput extends PureComponent {
    static contextType = FormContext;
    constructor(props) {
        super(props);
        this.state = {
            isEmpty: this.props.inputProps
                ? !this.props.inputProps.defaultValue
                : true,
            hasError: false,
        };
    }
    onChange = (event) => {
        if (this.props.onChange) {
            if (!event.target.value && this.props.isRequired) {
                this.setState({ hasError: true, isEmpty: true });
            } else if (
                event.target.value &&
                this.props.isRequired &&
                this.state.isEmpty
            ) {
                this.setState({ hasError: false, isEmpty: false });
            } else if (!event.target.value) {
                this.setState({ hasError: false, isEmpty: true });
            }
            this.props.onChange(event);
        }
    };

    keyDown = async (e) => {
        if (e.which === 13 && this.props.trim !== false) {
            const { name } = this.props;
            const form = this.context.form.current;
            const value =
                form.getFieldValue(this.props.name) || '';
            if (value) {
                if (value.startsWith(' ') || value.endsWith(' ')) {
                    form.setFieldsValue({
                        [name]: value.trim(),
                    });
                }
            }
            this.clearSpace();
        }
    };
    onBlur = () => {
        const form = this.context.form.current;
        const value = form.getFieldValue(this.props.name) || '';

        if (this.props.trim !== false) {
            //&& this.props.type !== 'password'
            form.setFieldsValue({
                [this.props.name]: value.trim(),
            });
        }
        this.clearSpace();
    };

    clearSpace = () => {
        const form = this.context.form.current;
        if (
            this.props.rules &&
            this.props.rules.find((f) => f.type === 'email')
        ) {
            const { name } = this.props;
            const value =
                form.getFieldValue(this.props.name) || '';
            if (value) {
                if (value.startsWith(' ') || value.endsWith(' ')) {
                    form.setFieldsValue({
                        [name]: value.replace(/ /g, ''),
                    });
                }
            }
        }
    };

    render() {
        const {
            name,
            type,
            value,
            label,
            rules,
            placeholder,
            className,
            colon,
            disabled,
            maxLength,
            addonBefore,
            tip,
            suffix
        } = this.props;
        const form = this.context.form;
        console.log(form.current)
        console.log("form")
        return (
            <Form.Item
                className={`form-input ${className}`}
                label={label}
                initialValue={value}
                colon={colon === false ? false : true}
                name={name} rules={typeof rules === 'function' ? rules(form.current) : rules}
            >
                    <Input
                        size="large"
                        onChange={this.onChange}
                        type={type || 'text'}
                        disabled={disabled}
                        placeholder={placeholder}
                        onKeyDown={this.keyDown}
                        maxLength={maxLength || 255}
                        onBlur={this.onBlur}
                        addonBefore={addonBefore}
                        suffix={
                            suffix
                          }
                    />
               
                {tip}
            </Form.Item>
        );
    }
}
