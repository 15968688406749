import React, { PureComponent } from 'react';
import {Form} from "antd";

//import '@ant-design/compatible/assets/index.css';


import { Spin } from 'antd';
import { FormContext } from '../context';

class FormContainer extends PureComponent {
    submiting = false;
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            sping: false,
        };
        props.onRef && props.onRef(this);
    }

    onFinish = async (values) => {
        console.log(values)
        console.log("values")
        if (!this.submiting) {
            this.submiting = true;

            if (this.props.loading) {
                this.setState({ sping: true });
            }

            try {
                await this.props.onSubmit(values);
            } catch (e) {
                console.log(e)
            }
            this.submiting = false;

            if (this.props.loading) {
                this.setState({ sping: false });
            }
        }
        // this.props.form.validateFieldsAndScroll(
        //     null,
        //     options,
        //     async (err, values) => {
        //         if (!err && !this.submiting) {
        //             this.submiting = true;

        //             if (this.props.loading) {
        //                 this.setState({ sping: true });
        //             }

        //             try {
        //                 await this.props.onSubmit(values);
        //             } catch (e) {
        //                 console.log(e)
        //             }
        //             this.submiting = false;

        //             if (this.props.loading) {
        //                 this.setState({ sping: false });
        //             }
        //         }
        //     }
        // );
        // if(this.props.isReset){
        // this.props.form.resetFields();
        // }
    };
    onFinishFailed = () => {
        const options = {
            scroll: {
                offsetTop: 120,
            },
        };
        this.formRef.current.scrollToField(null, options)

    }
    clearValues = () => {
        const values = this.formRef.current.getFieldsValue();

        Object.keys(values).forEach((key) => {
            values[key] = '';
        });
        this.formRef.current.setFieldsValue(values);
    };

    render() {
        const { children, className, layout, initialValues } = this.props;
        return (
            <Spin spinning={this.state.sping}>
                <Form ref={this.formRef}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    layout={layout}
                    className={className}
                    initialValues={initialValues}
                >
                    <FormContext.Provider
                        value={{
                            form: this.formRef,
                        }}
                    >
                        {children}
                    </FormContext.Provider>
                </Form>
            </Spin>
        );
    }
}

export default (FormContainer);
