import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { message } from 'antd';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import 'material-design-iconic-font/dist/css/material-design-iconic-font.min.css';
import 'react-datetime/css/react-datetime.css';

const _message_error = message.error;

message.error = msg => {
    if (msg) {
        _message_error(msg);
    }
};

console.log(`ENV: ${process.env.NODE_ENV}`);
// console.log(process.env);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <App />
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
