import React, { PureComponent } from 'react';
import {Form} from "antd";

//import '@ant-design/compatible/assets/index.css';


import { InputNumber } from 'antd';
import { FormContext } from '../context';

export default class FormNumberInput extends PureComponent {
    static contextType = FormContext;
    constructor(props) {
        super(props);
        this.state = {
            isEmpty: this.props.inputProps
                ? !this.props.inputProps.defaultValue
                : true,
            hasError: false,
        };
    }
    onChange = (value) => {
        if (this.props.onChange) {
            if (!value && value !== 0 && this.props.isRequired) {
                this.setState({ hasError: true, isEmpty: true });
            } else if (
                (value || value === 0) &&
                this.props.isRequired &&
                this.state.isEmpty
            ) {
                this.setState({ hasError: false, isEmpty: false });
            } else if (!(value || value === 0)) {
                this.setState({ hasError: false, isEmpty: true });
            }
            this.props.onChange(value);
        }
    };
    render() {
        const {
            name,
            value,
            label,
            min,
            max,
            rules,
            placeholder,
            className,
            tip,
            disabled,
            condition,
        } = this.props;
        const form = this.context.form.current;
        if (condition) {
            console.log({ condition });
            if (!condition(form.getFieldsValue())) {
                return <></>;
            }
        }
        return (
            <Form.Item initialValue={value} className={`form-input`} label={label} name={name} rules={rules}>
              
                    <InputNumber
                        min={min}
                        max={max}
                        disabled={disabled}
                        onChange={this.onChange}
                        className={`${className || ''}`}
                        placeholder={placeholder}
                    />,
               
                {tip}
            </Form.Item>
        );
    }
}
