import React, { PureComponent } from 'react';
import {Form} from "antd";

//import '@ant-design/compatible/assets/index.css';


import { FormContext } from '../context';

export default class FormTextarea extends PureComponent {
    static contextType = FormContext;
    constructor(props) {
        super(props);
        this.state = {
            isEmpty: this.props.inputProps
                ? !this.props.inputProps.defaultValue
                : true,
            hasError: false
        };
    }
    onChange = event => {
        if (this.props.onChange) {
            if (!event.target.value && this.props.isRequired) {
                this.setState({ hasError: true, isEmpty: true });
            } else if (
                event.target.value &&
                this.props.isRequired &&
                this.state.isEmpty
            ) {
                this.setState({ hasError: false, isEmpty: false });
            } else if (!event.target.value) {
                this.setState({ hasError: false, isEmpty: true });
            }
            this.props.onChange(event);
        }
    };
    render() {
        const {
            name,
            value,
            label,
            rules,
            placeholder,
            className,
            rows,
            disabled,
            maxLength
        } = this.props;

        return (
            <Form.Item className={`form-textarea`} label={label}
            name={name}
            rules={rules}
            initialValue={value}>
                    <textarea
                        placeholder={placeholder}
                        className={`${className || ''}`}
                        onChange={this.onChange}
                        rows={rows || 5}
                        disabled={disabled}
                        maxLength={maxLength || 1000}
                    ></textarea>
            </Form.Item>
        );
    }
}
