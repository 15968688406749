
// const getCookies = function () {
//     let pairs = document.cookie.split(';');
//     let cookies = {};
//     for (let i = 0; i < pairs.length; i++) {
//         let pair = pairs[i].split('=');
//         cookies[(pair[0] + '').trim()] = unescape(pair[1]);
//     }
//     return cookies;
// };
// try {
//     const cookies = getCookies();
//     if (!sessionStorage.getItem(AppConstants.AUTH)) {
//         Object.keys(cookies).forEach((key) => {
//             sessionStorage.setItem(key, cookies[key]);
//         });
//     }
// } catch (e) { }

function getCookie(name, includesSession = true) {
    if (includesSession && sessionStorage.getItem(name)) {
        return unescape(sessionStorage.getItem(name));
    }
    // let arr,
    //     reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
    // arr = document.cookie.match(reg);

    // if (arr) return unescape(arr[2]);
    else return null;
}

function setCookie(name, value) {
    let Days = 30;
    let exp = new Date();
    exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
    // document.cookie = `${name}=${escape(value)};path=/;`; //expires=${exp.toGMTString()};
    sessionStorage.setItem(name, escape(value));
}
function delCookie(name) {
    let exp = new Date();
    exp.setTime(exp.getTime() - 1);
    // let cval = getCookie(name, false);
    sessionStorage.removeItem(name);
    // if (cval != null) {
    //     document.cookie =
    //         name + '=' + cval + ';expires=' + exp.toGMTString() + ';path=/;';
    // }
}

export default {
    get: getCookie,
    set: setCookie,
    del: delCookie,
};
