import React, { Component } from 'react';
import { Button ,Tooltip} from 'antd';
import FullLayout from '../../../layout/full-layout/full-layout';
import { FormInput, FormContainer } from '../../../module/form';
import { account } from '../../../api';
import popup from '../../../components/popup';
import { required } from '../../../utils/validator';
import './password-reset.scss';
// import { getQueryParam } from "utils"
import { FormattedMessage, injectIntl } from 'react-intl';

class PasswordReset extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {
        //Find token information
    }

    handleSubmit = async (values) => {
        try {
            await account.passwordReset(values.password, values.newpassword);
            setTimeout(() => this.props.history.push('/organizations'), 100);
        } catch (e) {
            popup.error(e.message);
        }
    };

    password = '';
    confirmPassword = '';
    compareToFirstPassword = (rule, value, callback) => {
        this.confirmPassword = value;
        if (value && value !== this.password) {
            callback(
                <FormattedMessage
                    id="change_password.password.error"
                    defaultMessage="The passwords do not match"
                />,
            );
        } else {
            callback();
        }
    };

    passwordValidation = (rule, value, callback) => {
        //must have length greater than or equal to 6
        if (value.length < 8) {
            callback(
                <FormattedMessage
                    id="change_password.password.length_error"
                    defaultMessage="must have length greater than or equal to 8!"
                />,
            );
        } else if (!/[a-z]/.test(value)) {
            callback(
                <FormattedMessage
                    id="change_password.password.lowercase_error"
                    defaultMessage="Password must have lowercase characters!"
                />,
            );
        } else if (!/[A-Z]/.test(value)) {
            callback(
                <FormattedMessage
                    id="change_password.password.uppercase_error"
                    defaultMessage="Password must have uppercase character!"
                />,
            );
        } else if (!/[.!@#$%^&*]/.test(value)) {
            callback(
                <FormattedMessage
                    id="change_password.password.symbol_error"
                    defaultMessage="Password must have symbol characters(.!@#$%^&*)!"
                />,
            );
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        this.password = value;
        // if (value) {
        // }
        callback();
    };

    render() {
        const { intl } = this.props;
        return (
            <FullLayout>
                <div className="login forget-password ">
                    <FormContainer
                        onSubmit={this.handleSubmit}
                        className="login-form"
                        loading={true}
                    >
                        <div className="text-center">
                            <h1 className="title">
                                <FormattedMessage
                                    id="password_reset.title"
                                    defaultMessage="Reset Password"
                                />
                            </h1>
                            <h6 className="txt-grey sub-title">
                                <FormattedMessage
                                    id="password_reset.subtitle"
                                    defaultMessage="Please create your new password."
                                />
                            </h6>
                        </div>
                        <br />
                        <FormInput
                            label={
                                <FormattedMessage
                                    id="password_reset.password"
                                    defaultMessage="Old Password"
                                />
                            }
                            name="password"
                            type="password"
                            rules={[
                                required(
                                    <FormattedMessage
                                        id="password_reset.password.required"
                                        defaultMessage="Please input your password!"
                                    />,
                                ),
                                {
                                    validator: this.validateToNextPassword,
                                },
                                //this.passwordValidation,
                            ]}
                            placeholder="Old Password"
                        />
                        <FormInput
                            label={
                                <FormattedMessage
                                    id="password_reset.new_password"
                                    defaultMessage="New Password"
                                />
                            }
                            name="newpassword"
                            type="password"
                            rules={[
                                required(
                                    <FormattedMessage
                                        id="password_reset.new_password.required"
                                        defaultMessage="Please input your password!"
                                    />,
                                ),
                                {
                                    validator: this.validateToNextPassword,
                                },
                                this.passwordValidation,
                            ]}
                            placeholder={intl.formatMessage({
                                id: 'password_reset.new_password',
                                defaultMessage: 'New Password',
                            })}
                            suffix={
                                <Tooltip title="Password MUST be a minimum of 8 characters with a combination of upper and lower case letters and at least one special character (! @ # $ % ^ & * )">
                                   <i className="fa fa-info-circle" aria-hidden="true" ></i>
                                </Tooltip>
                            }
                        />
                        <FormInput
                            label={
                                <FormattedMessage
                                    id="password_reset.confirm_password"
                                    defaultMessage="Confirm New Password"
                                />
                            }
                            name="confirmPassword"
                            type="password"
                            rules={[
                                required(
                                    <FormattedMessage
                                        id="password_reset.confirm_password.required"
                                        defaultMessage="Confirmed Password is required!"
                                    />,
                                ),
                                {
                                    validator: this.compareToFirstPassword,
                                },
                            ]}
                            placeholder={intl.formatMessage({
                                id: 'password_reset.confirm_password',
                                defaultMessage: 'Confirm New Password',
                            })}
                            suffix={
                                <Tooltip title="Password MUST be a minimum of 8 characters with a combination of upper and lower case letters and at least one special character (! @ # $ % ^ & * )">
                                   <i className="fa fa-info-circle" aria-hidden="true" ></i>
                                </Tooltip>
                            }
                        />

                        <Button
                            shape="round"
                            size="large"
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                        >
                            <FormattedMessage
                                id="password_reset.reset"
                                defaultMessage="Reset"
                            />
                        </Button>
                    </FormContainer>
                </div>
            </FullLayout>
        );
    }
}

export default injectIntl(PasswordReset);
