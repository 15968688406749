const getEnvironmentConfig = () => {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    let envConfig;

    // Base configurations for each environment
    const envConfigs = {
        prod: {
            CREATE_ACCOUNT_ENDPOINT: 'https://my.spectralink.net/signup/set-password',
            CURRENT_ENDPOINT: 'https://my.spectralink.net/',
            S3_RESOURCE_ENDPOINT: 'https://myapi2.spectralink.net/api/static/resources?key=',
            REACT_APP_API_ENDPOINT: 'https://myapi2.spectralink.net/api/',
            REACT_APP_GATEWAYLESS_API_ENDPOINT: 'https://go.amie.spectralink.net/api/',
            NODE_ENV: 'production',
            show_language: false,
            APILOGGING: false,
            DISABLE_AUTO_LOGOUT: false
        },
        dev: {
            CREATE_ACCOUNT_ENDPOINT: 'https://my2.dev-amie.spectralink.net/signup/set-password',
            CURRENT_ENDPOINT: 'https://my2.dev-amie.spectralink.net/',
            S3_RESOURCE_ENDPOINT: 'https://myapi2.dev-amie.spectralink.net/api/static/resources?key=',
            REACT_APP_API_ENDPOINT: 'https://myapi2.dev-amie.spectralink.net/api/',
            REACT_APP_GATEWAYLESS_API_ENDPOINT: 'https://go.dev-amie.spectralink.net/api/',
            NODE_ENV: 'development',
            show_language: false,
            APILOGGING: true,
            DISABLE_AUTO_LOGOUT: true
        },
        lab: (labNumber) => ({
            CREATE_ACCOUNT_ENDPOINT: `https://my2.lab${labNumber}.dev-amie.spectralink.net/signup/set-password`,
            CURRENT_ENDPOINT: `https://my2.lab${labNumber}.dev-amie.spectralink.net/`,
            S3_RESOURCE_ENDPOINT: `https://myapi2.lab${labNumber}.dev-amie.spectralink.net/api/static/resources?key=`,
            REACT_APP_API_ENDPOINT: `https://myapi2.lab${labNumber}.dev-amie.spectralink.net/api/`,
            REACT_APP_GATEWAYLESS_API_ENDPOINT: `https://go.lab${labNumber}.dev-amie.spectralink.net/api/`,
            NODE_ENV: 'development',
            show_language: false,
            APILOGGING: true,
            DISABLE_AUTO_LOGOUT: true
        }),
        local: {
            CREATE_ACCOUNT_ENDPOINT: 'http://localhost:3004/signup/set-password',
            CURRENT_ENDPOINT: 'http://localhost:3004/',
            S3_RESOURCE_ENDPOINT: 'http://localhost:8001/api/static/resources?key=',
            REACT_APP_API_ENDPOINT: 'http://127.0.0.1:8001/api/',
            REACT_APP_GATEWAYLESS_API_ENDPOINT: 'https://go.lab4.dev-amie.spectralink.net/api/',
            NODE_ENV: 'development',
            show_language: false,
            APILOGGING: true,
            DISABLE_AUTO_LOGOUT: true
        }
    };

    // Determine environment based on the hostname parts
    const prefix = parts[0];
    const mainDomain = parts.slice(-2).join('.'); 

    if (mainDomain === 'spectralink.net') {
        if (parts.includes('dev-amie')) {
            const labMatch = parts[1].match(/^lab(\d+)$/);
            if (labMatch && (prefix === 'my2')) {
                const labNumber = labMatch[1];
                envConfig = envConfigs.lab(labNumber);
            } else if (prefix === 'my2') {
                envConfig = envConfigs.dev;
            }
        } else if (prefix === 'my2') {
            envConfig = envConfigs.prod;
        }
    } else if (hostname === 'localhost') {
        envConfig = envConfigs.local;
    }
    
    if (envConfig.APILOGGING)
    console.log(envConfig);

    return envConfig;
};

export default getEnvironmentConfig;
