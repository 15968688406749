import React, { PureComponent } from 'react';
import { Form, Select } from 'antd';
import { FormContext } from '../context';

export default class FormSelect extends PureComponent {
    static contextType = FormContext;

    setFieldsValue = (value) => {
        const form = this.context.form.current;
        console.log("formselect");
        console.log(form);
        form.setFieldsValue({
            [this.props.name]: value,
        });
    };

    getPlaceholder = () => {
        const { placeholder, optional } = this.props;
        if (optional === true) {
            return '--Please Choose--';
        }
        return optional ? optional : placeholder || '--Please Choose--';
    };

    componentDidMount() {
        this.props.onRef && this.props.onRef(this);
    }

    render() {
        const {
            options,
            value,
            name,
            rules,
            label,
            placeholder,
            onChange,
            colon,
            style,
            defaultOption,
            disabled,
            tip,
            className,
        } = this.props;

        const selectOptions = [
            ...(defaultOption ? [{ value: '', label: '--Please Choose--' }] : []),
            ...(options || []).map((option) => ({
                value: option.value,
                label: option.text,
            })),
        ];

        console.log("formselect value")
        console.log(name)
        console.log(value || value === 0 || value === false ? value : '')
        console.log(value)

        return (
            <Form.Item
                label={label}
                className={`form-select ${className}`}
                colon={colon === false ? false : true}
                initialValue={value || value === 0 || value === false ? value : ''}
                rules={rules}
                name={name}
                extra={tip && <small className="form-item-tip">{tip}</small>}
            >
                    <Select
                        disabled={disabled}
                        showSearch
                        placeholder={placeholder || this.getPlaceholder()}
                        name={name}                        
                        style={style}
                        onChange={onChange}
                        filterOption={(input, option) => {
                            return (
                            option.label &&
                            option.label.toLowerCase().includes(input.toLowerCase())
                            );
                        }}
                    options={selectOptions}
                />
            </Form.Item>
        );
    }
}