import { get, post, put, del, formdata, table, download } from './request';
import getEnvironmentConfig from 'config/config';
const config = getEnvironmentConfig();

export const app = {
    customers: (filter, sort, pagination) =>
        table('/customers', filter, sort, pagination),
    sites: (filter, sort, pagination) =>
        table('/sites', filter, sort, pagination),
    groups: () => table('/groups'),
    monthlySummary: (month) =>
        get('/systems/monthly-summary', {
            month,
        }),
};

//dataDictionary
export const account = {
    signIn: async (username, password, language, gatewayId) => {
        return await post('/auth/login', {
            username,
            password,
            language,
            ...(gatewayId && { gatewayId }),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
    },
    userfactor: (email) =>
        post('/auth/user-factor', {
            username: email,
        }),
    forgotPassword: (email) =>
        post('/auth/forgot-password', {
            email,
        }),
    resetPassword: (key, code, password) =>
        put('/auth/reset-password', {
            key,
            code,
            password,
        }),
    passwordReset: (password, newpassword) =>
        put('/auth/password-reset', {
            password,
            newpassword,
        }),
    getAuthorizationCode: (values) => post('/auth/authorization_code', values),
    resetPasswordInfo: (token) => get('/auth/reset-password?token=' + token),
    current: () => get('/accounts/current'),
    setOrganization: (organizationId) =>
        put('/accounts/set-organization', {
            organizationId,
        }),
    accepteula: () =>
        put(`/accounts/accepteula`,),
    setExpiretip: () =>
        put('/accounts/set-expiretip',),
    setLocation: (locationId) =>
        put('/accounts/set-location', {
            locationId,
        }),
    setCurrentAccount: (accountId) =>
        put('/accounts/set-account', {
            accountId,
        }),
    updateProfile: (values) => put('/system-users/update-profile', values),
    updateAvatar: (file) =>
        formdata('/system-users/update-avatar', {
            file,
        }),
    clearAvatar: () => put(`/system-users/clear-avatar`),

    autoLogin: () => { },
    table: (filter, sort, pagination) =>
        table('/accounts', filter, sort, pagination),
    accountbrandingtable: (filter, sort, pagination) =>
        table('/accounts/brandingaccount', filter, sort, pagination),
    getOptions: () => get('/accounts/options'),
    getStatusInfos: (accountIds, ajaxConfig) =>
        post('/accounts/get-status', { accountIds }, ajaxConfig),
    delete: (accountId) => del('/accounts/' + accountId),
    update: (accountId, model) => put('/accounts/' + accountId, model),
    maintenance: () => post('/auth/maintenance'),
    checkmaintenance: (key) => post('/auth/check-maintenance-key', { key: key }),
    getrelease: () => get('/accounts/getrelease'),
    role: {
        table: (filter, sort, pagination, tenantId) =>
            table('/role/' + (tenantId || 'none'), filter, sort, pagination),
        get: (id) => get('/role/' + id),
        update: (roleId, model) => put(`/role/${roleId}`, model),
        add: (model) => post('/role', model),
        delete: (id) => del('/role/' + id),
        getOptions: () => get('/role/options'),
        getRolePermission: (roleId) => get(`/role/${roleId}/permission`),
        getPermissions: () => get(`/role/permissions`),
        updateRolePermission: (roleId, permissions) =>
            put(`/role/${roleId}/permission`, {
                permissions,
            }),
    },
};

export const onboarding = {
    authenticationLicense: (licenseKey, organizationId) =>
        post('/auth/license', {
            licenseKey,
            organizationId: organizationId
        }),
    createAccoutnAndOrganization: (
        licenseKey,
        organizationName,
        deploymentRegion,
        superUser,
        name,
        phone
    ) =>
        post('/onboarding/create-accoutn-and-organization', {
            licenseKey,
            organizationName,
            deploymentRegion,
            superUser,
            name,
            phone,
            createAccountUrl: config.CREATE_ACCOUNT_ENDPOINT,
            onboardingProcessUrl: `${config.CURRENT_ENDPOINT}signup/create-locations`,
        }),
    craeteLocations: (locations, key) =>
        post('/onboarding/create-locations', {
            locations,
            key,
        }),
    setPassword: (password, key, passwordRecovery) =>
        post('/onboarding/set-password', {
            password,
            key,
            passwordRecovery,
            loginUrl: `${config.CURRENT_ENDPOINT}login`,
        }),
    verifyUserKey: (key) =>
        post('/onboarding/verify-user-key', {
            key,
        }),
    verifyContinueKey: (key) =>
        post('/onboarding/verify-continue-key', {
            key,
        }),
    assignToSuperUser: (key) =>
        post('/onboarding/assign-to-super-user', {
            key,
            onboardingProcessUrl: `${config.CURRENT_ENDPOINT}signup/create-locations`,
        }),
};

export const client = {
    table: (filter, sort, pagination) =>
        table('/clients', filter, sort, pagination),
};

export const alerts = {
    table: (filter, sort, pagination, option) =>
        table('/alerts', filter, sort, pagination, option),
    update: (id, model) => put('/alerts/' + id, model),
    get: (id) => get('/alerts/' + id),
    getTypes: () => get('/alerts/types'),
    getStatus: () => get('/alerts/status'),
    getTenantsAlertCount: () => get('/alerts/alets-count/'),
    exportnow: (values) => post('/alerts/exportnow', values),
    getCriteriaDefinitions: () => get('/alerts/options/criteria-definitions'),
    getAllCriteriaDefinitions: () => get('/alerts/criteria-definitions'),
    addCriteriaDefinitions: (v) => post('/alerts/criteria-definitions', v),
    updateCriteriaDefinitions: (id, v) =>
        put('/alerts/criteria-definitions/' + encodeURIComponent(id), v),
    deleteCriteriaDefinitions: (id) =>
        del('/alerts/criteria-definitions/' + encodeURIComponent(id)),

    getAlertRules: (filter, sort, pagination) =>
        table('/alerts/rules', filter, sort, pagination),
    getAlertRule: (ruleId) => get('/alerts/rules/' + ruleId),
    getRuleOptionsOperationsDefinitions: () =>
        get('/alerts/rules/options/operations-definitions'),

    getRuleOperationsDefinitions: () =>
        get('/alerts/rules/operations-definitions'),
    addRuleOperationsDefinition: (v) =>
        post('/alerts/rules/operations-definitions', v),
    updateRuleOperationsDefinition: (id, v) =>
        put(
            '/alerts/rules/operations-definitions/' + encodeURIComponent(id),
            v
        ),
    deleteRuleOperationsDefinition: (id) =>
        del('/alerts/rules/operations-definitions/' + encodeURIComponent(id)),

    updateAlertRule: (rule_id, values) =>
        put('/alerts/rules/' + rule_id, values),
    addAlertRule: (values) => post('/alerts/rules/', values),
    deleteAlertRule: (ruleIds) =>
        post('/alerts/rules/batch-delete', { ruleIds }),
};

export const notifications = {
    getNotifications: (data) => get('/notifications', data),
    getUserNotification: (notificationId) =>
        get(`/notifications/${notificationId}/get`),
    readNotification: (notificationId) =>
        put(`/notifications/${notificationId}/read`),
    unreadNotification: (notificationId) =>
        put(`/notifications/${notificationId}/unread`),
    readAllNotifications: (type) => post('/notifications/read-all/' + type),
    unreadAllNotifications: (type) => post('/notifications/unread-all/' + type),
    clearAllNotifications: () => post('/notifications/clear-all'),
};

export const devices = {
    table: (filter, sort, pagination, option) =>
        table('/devices', filter, sort, pagination, option),
    detail: (id) => get(`/devices/${id}/detail`),
    total: () => get(`/devices/total`),
    historyTable: (deviceId, filter, sort, pagination) =>
        table(`/devices/${deviceId}/histories`, filter, sort, pagination),
    callhistoryTable: (deviceId, filter, sort, pagination) =>
        table(`/devices/${deviceId}/call-histories`, filter, sort, pagination),
    batteryHistorian: (deviceId) =>
        get(`/devices/${deviceId}/battery-historian`),
    batteryHistorian_wakelock: (deviceId) =>
        get(`/devices/${deviceId}/battery-historian/wakelock`),

    summary: (tag, timeRange) =>
        get(`/devices/summary`, {
            tag, timeRange
        }),
    getCheckInSummary: (tag, timeRange,page) => get(`/devices/summary/check-in`, {
        tag, timeRange,page
    }),
    getSparesTotal: (tag, timeRange) => get('/administration/locations/sparestotal', {
        tag, timeRange
    }),
    getUtilizationSummary: (tag, timeRange) =>
        get(`/devices/summary/utilization`, {
            tag,
            timeRange,
        }),

    activity: (id) => get(`/devices/${id}/activity`),
    addTag: (id, tag) =>
        post(`/devices/${id}/tags`, {
            tag,
        }),
    getTags: () => get(`/devices/tags`),
    deleteTag: (id, tagId) => del(`/devices/${id}/tags/${tagId}`),
    getModels: () => get('/devices/models'),
    getVersityVersions: () => get('/devices/versity-versions'),
    sendMessage: (id, content) =>
        post(`/devices/${id}/sendmessage`, {
            content,
        }),
    messageTable: (id, filter, sort, pagination) =>
        table(`/message/${id}/getmessage`, filter, sort, pagination),
    getCallAps: (deviceSerial, id) =>
        get(`/devices/${deviceSerial}/calls/${id}/aps`),
    getCallMosAps: (deviceSerial, id) =>
        get(`/devices/${deviceSerial}/calls/${id}/mosAps`),
    getCallsByCallId: (deviceSerial, id) =>
        get(`/devices/${deviceSerial}/calls/${id}/log`),

    getdeviceStatusInfos: (locationId) =>
        get(`/devices/device-status-infos`, {
            locationId,
        }),

    setBlackList: (serials, locationId) =>
        put(`/devices/set-black`, {
            serials,
            locationId,
        }),
    //GATEWAYLESS API
    getEnrollment : () => 
        get('/device/enrollkey'),
    createEnrollment: (random, ttl) =>
        post('/device/enrollkey', {
            random,ttl
        }),
    endEnrollment : (key) => 
            put('/device/endenrollkey', {
                key
            }),
    refreshEnrollStates: () =>
        get('/device/enrollstates'),
    /**
     * Retire device
     */
    retire: (deviceSerial) => put(`/devices/${deviceSerial}/retire`),
    reactivate: (deviceSerial) => put(`/devices/${deviceSerial}/reactivate`),
};

export const batteries = {
    table: (filter, sort, pagination, option) =>
        table('/batteries', filter, sort, pagination, option),
    detail: (id) => get(`/batteries/${id}/detail`),
    total: () => get(`/batteries/total`),
    historyTable: (batteryId, filter, sort, pagination) =>
        table(`/batteries/${batteryId}/histories`, filter, sort, pagination),
    getSummaryData: (range, tag) =>
        get(`/batteries/summary`, {
            range,
            tag,
        }),
    getTopAppsSummaryData: (range, tag) => get(`/batteries/summary/top-apps`, {
        range,
        tag,
    }),
    getHistorianData: (batterySerialNumber) =>
        get(`/batteries/${batterySerialNumber}/historian`),
    batteryHistorian_wakelock: (batterySerialNumber) =>
        get(`/batteries/${batterySerialNumber}/battery-historian/wakelock`),
    retire: (batterySerial) => put(`/batteries/${batterySerial}/retire`),
    reactivate: (batterySerial) =>
        put(`/batteries/${batterySerial}/reactivate`),
};

export const site = {
    locations: {
        table: (filter, sort, pagination) =>
            table('/sites', filter, sort, pagination),
        get: (id) => get('/sites/' + id),
        update: (id, model) => put('/sites/' + id, model),
        add: (model) => post('/sites', model),
        delete: (id) => del('/sites/' + id),
    },
    floors: {
        table: (filter, sort, pagination) =>
            table('/sites/floors', filter, sort, pagination),
        add: () => post('/sites/floors'),
        update: () => put('/sites/floors'),
        delete: () => del('/sites/floors'),
    },
};

export const locations = {
    table: (filter, sort, pagination, options) =>
        table('/locations', filter, sort, pagination, options),
    checkNameExists: (locationName) =>
        get(`/locations/is-exists-location`, {
            locationName,
        }),
    checkVirtualExists: () =>
        get(`/locations/is-exists-virtual-location`),
    getLocationFloorplans: (locationId) =>
        get(`/locations/${locationId}/floorplans`),
    getApsByLocationId: (locationId, range) =>
        get(`/locations/${locationId}/aps`, { range }),
    getCountriesOfLocationCount: () =>
        get('/locations/countries-of-location-count'),
    getById: (id) => get('/locations/' + id),
    getLocationContacts: (id) => get(`/locations/${id}/contacts`),
    getLocationGateways: (locationId) =>
        table(`/locations/${locationId}/gateways`),
    getLocationFloors: (id) => get(`/locations/${id}/floors`),
    getLocationFloorInfo: (locationId, id) =>
        get(`/locations/${locationId}/floors/${id}`),
    addLocationFloors: (id, floors) =>
        post(`/locations/${id}/floors`, {
            floors,
        }),
    getLocationDropdownList: () => get(`/locations/dropdown-list`),
    getOptions: (organizationId) =>
        get(`/locations/options`, {
            organizationId,
        }),
    getAccountLocations: (accountId) =>
        get(`/locations/account-locations/${accountId}`),

    addLocation: (location) => post('/locations', location),
    deleteLocation: (locationId) => del(`/locations/${locationId}`),
    addLocationByAdmin: (location) =>
        post('/locations/createbyadmin', location),
    updateGatewayByAdmin: (id, gateway) =>
        put(`/administration/locations/updategateway/${id}`, gateway),
    updateGatewayCert: (id, sanEntry) =>
        post(`/administration/locations/updategatewaycert/${id}`, { sanEntry }),

    addLocationByUser: (location, gateway) =>
        post('/locations/create-by-user', {
            location,
            gateway,
        }),

    updateLocationContact: (locationId, contactId, payload) =>
        put(`/locations/${locationId}/contacts/${contactId}`, payload),
    addLocationContact: (locationId, payload) =>
        post(`/locations/${locationId}/contacts/`, payload),
    deleteLocationContact: (locationId, contactId) =>
        del(`/locations/${locationId}/contacts/${contactId}`),
    deleteLocationFloors: (locationId, floorsId) =>
        del(`/locations/${locationId}/floors/${floorsId}`),

    bulkLoadAP: (id, file) =>
        formdata(`/locations/${id}/bulk-load-ap`, {
            file,
        }),
    //assignAPLocation: (locationId, floorId, apLocations, newAPs) => post(`/locations/${locationId}/floors/${floorId}/assign-ap`, { apLocations, newAPs }),
    updateFloor: (locationId, floorId, model) =>
        formdata(`/locations/${locationId}/floors/${floorId}`, model),
    assignAPs: (locationId, floorId, model) =>
        formdata(
            `/locations/${locationId}/floors/${floorId}/assign-base-stations`,
            model
        ),
    updateLocation: (locationId, location) =>
        put(`/locations/${locationId}/`, location),

    getLocationSumary: (filter, sort, pagination) =>
        table(
            '/administration/locations/location-sumary',
            filter,
            sort,
            pagination
        ),
    getGatewaySummary: (filter, sort, pagination) =>
        table(
            '/administration/locations/gateway-summary',
            filter,
            sort,
            pagination
        ),
    getGatewayPing: (filter, sort, pagination, t) =>
        table(
            '/administration/locations/gateway-ping',
            filter,
            sort,
            pagination,
            t
        ),
    //getLocationSummaryGatwayListByTenant: (id) => get(`/administration/locations/${id}/gateways`),

    getLocationRetiredBatteries: (filter, sort, pagination) =>
        table(
            `/administration/locations/retired-batteries`,
            filter,
            sort,
            pagination
        ),
    getRetiredDevicesByEventId: (locationId, eventId) =>
        get(
            `/administration/locations/${locationId}/retired-devices/${eventId}`
        ),
    getRetiredBatteriesByEventId: (locationId, eventId) =>
        get(
            `/administration/locations/${locationId}/retired-batteries/${eventId}`
        ),

    getGatewayById: (id) => get(`/administration/locations/gateway/${id}`),
    getLocationSummaryGatwayListByTenant: (id) =>
        get(`/administration/locations/${id}/gateway`),

    bulkLoadSpares: (locationId, file) =>
        formdata(`/administration/locations/${locationId}/bulk-load-spares`, {
            file,
        }),

    getLocationSpares: (filter, sort, pagination, locationId) =>
        table(
            `/administration/locations/${locationId}/spares`,
            filter,
            sort,
            pagination
        ),

    deleteLocationSpares: (locationId, serials) =>
        put(`/administration/locations/${locationId}/spares/delete`, {
            serials,
        }),
    addLocationSpares: (locationId, serial, model) =>
        post(`/administration/locations/${locationId}/spares`, {
            serial,
            model,
        }),

    getRetirementHistory: (filter, sort, pagination) =>
        table(
            `/administration/locations/retirement-history`,
            filter,
            sort,
            pagination
        ),

    getFloorMapByAp: (ap_bssid) =>
        get('/locations/floor-map-ap', {
            ap_bssid,
        }),

    getLocationLicenseSummary: (locationId) =>
        get(`/locations/${locationId}/licenses-summary`),
    getLocationLicenseSparesSummary: (locationId) =>
        get(`/locations/${locationId}/licenses-spares-summary`),
    deleteESData: (locationId, organizationId) =>
        del(`/locations/${locationId}/es`, { organizationId }),
};

export const tags = {
    getDeviceTags: () => get('/devices/tags'),
};
export const devicesMessage = {
    sendMessage: async (id, message, title) =>
        await post(`/message/${id}/sendmessage`, {
            message,
            title,
        }),
};
export const emailNotification = {
    sendEmailNotification: (
        message,
        send_trigger,
        recipients,
        subject,
        type,
        accountid
    ) =>
        post(`/emailnotification/emailnotification`, {
            message,
            send_trigger,
            recipients,
            subject,
            type,
            accountid,
        }),
    updateNotification: (
        message,
        send_trigger,
        recipients,
        subject,
        type,
        accountid
    ) =>
        put(`/emailnotification/emailnotification`, {
            message,
            send_trigger,
            recipients,
            subject,
            type,
            accountid,
        }),
    getEmailNotification: async (type) => {
        return await get('/emailnotification/detail/' + type);
    },
};
export const performance = {
    getSummaryData: () => get('/performance'),
    getCallPerformance: () => get('/performance/call-performance'),
    getCallPerformanceSummary: (timeRange, tag,ap,device) =>
        get('/performance/call-performance/summary', {
            timeRange, tag,ap,device
        }),
    getCallPerformanceByAP: (timeRange, tag,ap,device) =>
        get('/performance/call-performance/ap', {
            timeRange, tag,ap,device
        }),
    getCallPerformanceByDevice: (timeRange, tag,ap,device) =>
        get('/performance/call-performance/device', {
            timeRange, tag,ap,device
        }),

    getDroppedCallsSummary: (timeRange, tag,ap,device) =>
        get('/performance/dropped-calls/summary', {
            timeRange, tag,ap,device
        }),
    getDroppedCallsByAP: (timeRange, tag,ap,device) =>
        get('/performance/dropped-calls/ap', {
            timeRange, tag,ap,device
        }),
    getDroppedCallsByDevice: (timeRange, tag,ap,device) =>
        get('/performance/dropped-calls/device', {
            timeRange, tag,ap,device
        }),

    getLongHandoffSummary: (timeRange, tag,ap,device) =>
        get('/performance/long-handoff/summary', {
            timeRange, tag,ap,device
        }),
    getLongHandoffByAP: (timeRange, tag,ap,device) =>
        get('/performance/long-handoff/ap', {
            timeRange, tag,ap,device
        }),
    getLongHandoffByDevice: (timeRange, tag,ap,device) =>
        get('/performance/long-handoff/device', {
            timeRange, tag,ap,device
        }),

    getPacketLossByAP: (timeRange, tag,ap,device) =>
        get('/performance/packet-loss/ap', {
            timeRange, tag,ap,device
        }),
    getPacketLossByDevice: (timeRange, tag,ap,device) =>
        get('/performance/packet-loss/device', {
            timeRange, tag,ap,device
        }),
    getPacketLossSummary: (timeRange, tag,ap,device) =>
        get('/performance/packet-loss/summary', {
            timeRange, tag,ap,device
        }),

    getLowSignalStrengthByAP: (timeRange, tag,ap,device) =>
        get('/performance/low-signal-strength/ap', {
            timeRange, tag,ap,device
        }),
    getLowSignalStrengthByDevice: (timeRange, tag,ap,device) =>
        get('/performance/low-signal-strength/device', {
            timeRange, tag,ap,device
        }),
    getLowSignalStrengthSummary: (timeRange, tag,ap,device) =>
        get('/performance/low-signal-strength/summary', {
            timeRange, tag,ap,device
        }),
};

//dataDictionary
export const dataDictionary = {
    table: (filter, sort, pagination) =>
        table('/dicts', filter, sort, pagination),
    update: async (id, model) => {
        return await put('/dicts/' + id, model);
    },
    add: async (model) => {
        return await post('/dicts', model);
    },
    delete: (id) => del(`/dicts/${id}`),
    types: () => get(`/dicts/types`),
};
export const setting = {

    modify: async (models) => {
        return await post('/setting/modify',
            models
        );
    },
    update: async (models, tenantId) => {
        return await put('/setting/' + (tenantId || 'none'), {
            models: models,
        });
    },
    getAll: async (tenantId) => {
        return await get(`/setting/${tenantId || 'none'}/list`);
    },
};
export const ssidsAPI = {
    // update: async (models, tenantId) => {
    //     return await put('/ssids/' + (tenantId || 'none'), {
    //         models: models,
    //     });
    // },
    add: async (model, tenantId) => {
        return await post(`/ssids/${tenantId}`, model);
    },
    change: async (model, tenantId) => {
        return await put(`/ssids/${tenantId}`, model);
    },
    delete: (ssid, tenantId) => del(`/ssids/${tenantId}/${encodeURIComponent(ssid)}`),
    getAll: async (tenantId) => {
        return await get(`/ssids/${tenantId || 'none'}/list`);
    },
    getAllSSIDS: async (organizationId) => {
        return await get(`/ssids/${organizationId || 'none'}/allssidlist`);
    },
};
export const licenses = {
    table: (filter, sort, pagination, option) =>
        table('/administration/licenses', filter, sort, pagination, option),
    update: (id, model) => put('/administration/licenses/' + id, model),
    delete: (id) => del('/administration/licenses/' + id),
    updateEndDay: (id, model) => put('/administration/licenses/day/' + id, model),
    getLicenseInfoById: (id) => get(`/administration/licenses/${id}`),
    getLocationsByLicenseId: (licenseId) =>
        get(`/administration/licenses/${licenseId}/locations`),
    getNotificationsByLicenseId: (licenseId, filter, sort, pagination) =>
        table(
            `/administration/licenses/${licenseId}/notifications`,
            filter,
            sort,
            pagination
        ),

    addLicense: (key) =>
        post(`/administration/licenses`, {
            key,
        }),
    updateLicense: (licenseId, key) =>
        put(`/administration/licenses/${licenseId}`, {
            key,
        }),

    updateLicenseLocations: (licenseId, locations) =>
        post(`/administration/licenses/${licenseId}/locations`, {
            locations,
        }),

    getLicenseSummary: () => get(`/administration/licenses/summary`),
};

export const gateways = {
    updateHealth: (gatewayId, gatewayCreateCommand, selfSignedCert) =>
        formdata(`/gateways/${gatewayId}/health`, {
            gatewayCreateCommand,
            selfSignedCert,
        }),
    update: (gatewayId, gateway) => put(`/gateways/${gatewayId}`, gateway),
    updateInfo: (gatewayId, gateway) =>
        put(`/gateways/${gatewayId}/info`, gateway),
    getGatewayHealth: (filter, sort, pagination, option) =>
        table('/gateways/health', filter, sort, pagination, option),
    getGatewayHealthSum: () => get(`/gateways/gateway-health`),
    getGatewaySizeSum: () => get(`/gateways/gateway-size`),
    //getGatewaySoftwareSum: () => get(`/gateways/gateway-software`),  // Supporting gateway software version is for future development
    getGatewayHealths: (id) => get(`/gateways/${id}/health`),
    getGatewayCertExpDate: () => get(`/gateways/cert_exp_date`),
    getGatewayRecords: (gatewayId) => get(`/gateways/records`, { gatewayId }),
};

export const organizations = {
    table: (filter, sort, pagination, option) =>
        table('/organizations', filter, sort, pagination, option),
    getOptions: (accountId) =>
        get('/organizations/options', {
            accountId,
        }),
    isExists: (name) =>
        post('/onboarding/is-exists-organization', {
            name,
        }),
    add: (values) => post('/organizations', values),
    delete: (organziationId) => del('/organizations/' + organziationId),
    update: (organziationId, model) =>
        put('/organizations/' + organziationId, model),
};

export const adminUsers = {
    table: (filter, sort, pagination, option) =>
        table('/system-users', filter, sort, pagination, option),
    addUser: (model) =>
        post('/system-users', {
            ...model,
            loginUrl: `${config.CURRENT_ENDPOINT}login`,
        }),

    /**
     * params: {userId, roleId, locationIds,name,phone }
     */
    updateUser: (user) => put(`/system-users/${user.userId}`, user),
    destoryCurrentUser: (username) =>
        post(`/system-users/destory`, {
            username,
        }),
    deleteUser: (userId, accountId) =>
        del(`/system-users/${userId}`, {
            accountId,
        }),

    resetPassword: (userId) =>
        put(`/system-users/${userId}/reset-password`, {
            loginUrl: `${config.CURRENT_ENDPOINT}login`,
        }),
    unlock: (userId) => put(`/system-users/${userId}/unlock`),

    updateUserTimezone: (timezone, dateFormat, timeFormat) =>
        put(`/system-users/timezone`, {
            timezone,
            dateFormat,
            timeFormat,
        }),

    resendAmieStepEmail: (userId, email) =>
        post(`/system-users/resend-amie-step-email`, {
            userId,
            email,
            createAccountUrl: config.CREATE_ACCOUNT_ENDPOINT,
        }),
};

export const awsRegions = {
    getOptions: () => get('/aws-regions/options'),
};

export const timeZones = {
    getOptions: () => get('/time-zones/options'),
};

export const addresses = {
    getCountryOptions: () => get('/addresses/countries/options'),
    getSubdivisionOptions: () => get('/addresses/subdivisions/options'),
};

export const spectralinkAdmin = {
    getSpectralinkAdmin: () => get('/spectralink-admin'),
    getOverview: () => get('/spectralink-admin/overview'),
    updateBranding: (payload) =>
        formdata('/spectralink-admin/branding', payload),
    updateGlobalSettings: (payload) =>
        put('/spectralink-admin/global-settings', payload),
    updateEULA: (eula) =>
        put('/spectralink-admin/EULA', {
            eula,
        }),
    resetColorToDefault: (payload) =>
        put('/spectralink-admin/reset-color-to-default', payload),
    resetToDefaultLogo: (payload) =>
        put('/spectralink-admin/reset-to-default-logo', payload),

    getUserTableData: (filter, sort, pagination, option) =>
        table('/system-users/all', filter, sort, pagination, option),
};

export const alertThresholds = {
    add: (locationId, payload) =>
        post('/spectralink-admin/alert-thresholds', {
            tenantId: locationId,
            ...payload,
        }),
    update: (id, payload) =>
        put(`/spectralink-admin/alert-thresholds/${id}`, payload),
    delete: (id, alertThresholdTrigger) =>
        del(`/spectralink-admin/alert-thresholds/${id}`, {
            alertThresholdTrigger,
        }),
    table: (filter, sort, pagination) =>
        table('/spectralink-admin/alert-thresholds', filter, sort, pagination),
};

export const serviceProviders = {
    table: (filter, sort, pagination) =>
        table('/service-providers', filter, sort, pagination),
    generateAssociationLink: (data) =>
        post(`/service-providers/generate-association-link`, data),
    associationLink: (data) =>
        post(`/service-providers/association-link`, data),
    activateAssociationLink: (data) =>
        post(`/service-providers/activate-association-link`, data),

    update: (id, payload) => put('/service-providers/' + id, payload),
    delete: (id) => del('/service-providers/' + id),
};

export const systems = {
    exportMonthlySummaryPDF: (month, tenantId) =>
        download(
            `/systems/monthly-summary-pdf?month=${month}&tenantId=${tenantId}`,
            'monthly_summary',
            'pdf'
        ),
    sendMonthlySummaryPDF: (month, tenantId, subject, body, recipients) =>
        post(`/systems/send-monthly-summary-pdf`, {
            month,
            tenantId,
            subject,
            body,
            recipients,
        }),
};

export const archives = {
    getAll: () => get('/archives/organization'),
};
export const pagestatic = {
    getTermofuse: () => get('static/termofuse'),
    modify: async (models) => { return await post('/static/modify', models); },
    getlanguagebycode: () => get(`static/getlanguagebycode`),
    putTexts: (label, data) => put(`/static/${label}/texts`, data),
}
export const language = {
    getLabel: (label) => get(`/language/${label}/label`),
    getLabelLanguage: (label) => post(`/language/label_language`, { label: label }),
    getPageOption: () => get(`/language/pageoption`),
    getTexts: (label) => get(`/language/${label}/texts`),
    deleteLabels: (label) => del(`/language/${label}/label`),
    postLabel: (data) => post('/language/label', data),
    postText: (data) => post('/language/text', data),
    putTexts: (label, data) => put(`/language/${label}/texts`, data),
    labelTable: (filter, sort, pagination, option) =>
        table('/language/label', filter, sort, pagination, option),
    bulkUpload: (values) => formdata('/language/bulk-upload', values),
};
export const releasenotes = {
    get: (id) => get(`/releasenotes/${id}`),
    delete: (id) => del(`/releasenotes/${id}`),
    post: (data) => post('/releasenotes', data),
    put: (id, data) => put(`/releasenotes/${id}`, data),
    table: (filter, sort, pagination, option) =>
        table('/releasenotes', filter, sort, pagination, option)
};