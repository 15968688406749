import axios from 'axios';
import cookie from '../utils/cookie';
import getEnvironmentConfig from 'config/config';
import {
    logout,
    getCurrentOrganizationAvailableLocations,
} from '../utils/store';
import moment from 'moment';
import { AppConstants } from '../constants/constants';
import { saveAs } from 'file-saver';
import { message as messageAlert } from 'antd';
import store from 'reduxlocal/store';
const config = getEnvironmentConfig();

let autoExit = false;

const instance = axios.create({
    baseURL: config.REACT_APP_API_ENDPOINT,
    // baseURL: "/api/",
    //timeout: 60000
});
const gatewaylessInstance = axios.create({
    baseURL: config.REACT_APP_GATEWAYLESS_API_ENDPOINT,
    // baseURL: "/api/",
    //timeout: 60000
});
if(!config.DISABLE_AUTO_LOGOUT){
    setInterval(() => {
        const last_request_time = cookie.get(AppConstants.LAST_REQUERT_TIME, true);    
        const lastRequestTime = Number.parseInt(last_request_time);
        if (lastRequestTime) {
            if (moment().diff(moment(lastRequestTime), 'm') >= 15) {
                const state = store.getState();
                cookie.set(AppConstants.LAST_REQUERT_TIME, moment().valueOf());
                if (state && state.account && state.account.current) {
                    logout();
                }
            }
        }
    }, 10000); //10s
}

let tokenIsRefreshing = false;
const customAxios = async (url, type, data, config = {}) => {
    if(!['/auth/login', '/auth/refresh', '/auth/maintenance','/notifications'].includes(url)){
        cookie.set(AppConstants.LAST_REQUERT_TIME, moment().valueOf());
    }

    if (url !== '/auth/refresh' || !tokenIsRefreshing) {
        await new Promise(function (resolve) {
            const timer = setInterval(() => {
                if (!tokenIsRefreshing) {
                    clearInterval(timer);
                    resolve();
                }
            }, 100);
        });
    }

    if (url === '/auth/login') {
        autoExit = false;
        console.log('login');
    }

    //Check if token is expired before request. If so, jump to login
    let auth = cookie.get(AppConstants.AUTH);
    const tokenExp = cookie.get(AppConstants.TOKEN_EXP);
    const refreshToken = cookie.get(AppConstants.REFRESH_TOKEN);    
    //refresh the token (Exclude login requests
    if (
        !tokenIsRefreshing &&
        auth &&
        tokenExp &&
        refreshToken &&
        !['/auth/login', '/auth/refresh'].includes(url)
    ) {
        tokenIsRefreshing = true;
        // If the remaining time is less than 15 minutes  (Valid for one hour)
       
        if (
            moment(Number.parseInt(Number.parseInt(tokenExp)) * 1000).diff(
                moment(),
                'm'
            ) < 15
        ) {
            try {
                const organizationId = cookie.get(
                    AppConstants.CURRENT_ORGANIZATION
                );
                const locationId = cookie.get(AppConstants.CURRENT_LOCATION);
                const newToken = await customAxios('/auth/refresh', 'POST', {
                    refreshToken,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    organizationId,
                    locationId,
                });
                //set
                cookie.set(AppConstants.AUTH, newToken.token);
                cookie.set(AppConstants.TOKEN_EXP, newToken.exp);
                cookie.set(AppConstants.REFRESH_TOKEN, newToken.refreshToken);
            } catch (e) {
                logout();
                //logout
            }
        }
        tokenIsRefreshing = false;
    }

    const availableLocations = getCurrentOrganizationAvailableLocations();

    let options = {
        method: type,
        url: url,
        headers: {
            'content-type': 'application/json',
            Authorization: `bearer ${cookie.get(AppConstants.AUTH)}`,
            'Cache-Control': 'no-cache, no-transform, no-store',
            locationIds: availableLocations.map((p) => p.value).join(','),

        },
        ...(config || {}),
    };
    switch (type) {
        case 'GET':
        case 'DELETE':
            if (data) {
                options.url =
                    url +
                    '?' +
                    Object.entries(data)
                        .map((m) => m.join('='))
                        .join('&');
            }
            break;
        case 'POST':
        case 'PUT':
            options.data = data;
            break;
        case 'FORMDATA':
            {
                options.method = 'POST';
                options.headers['content-type'] = 'multipart/form-data';

                let _formdata = new FormData();
                Object.entries(data).forEach(([key, value]) =>
                    _formdata.append(key, value)
                );
                options.data = _formdata;
            }
            break;
        case 'DOWNLOAD':
            options.method = 'GET';
            options.headers['content-type'] = undefined;
            options.responseType = 'blob';
            break;
        default:
            break;
    }
    
    let APILOGGING = true;
    if( !config.APILOGGING ){
        APILOGGING = false;
    } else if ( options.url.startsWith('/notifications?timeRange=') ){
        if( options.method === 'GET' ){
            APILOGGING = false;
        }
    } else if ( options.url === '/auth/maintenance' ) {
        if( options.method === 'POST' ){
            APILOGGING = false;
        }
    }

    try{
        if(APILOGGING){
            console.log(`DEBUG REQUEST: ${options.method} ${options.url}`, options);        
        }
        var res;
        if (url == '/device/enrollkey' || url == '/device/enrollstates' || url == '/device/endenrollkey')  {
            res = await gatewaylessInstance(options)
        } else {
            res = await instance(options)
        }
        
        if(APILOGGING){
            console.log(`DEBUG RESPONSE: ${options.method} ${options.url}`, res.data);
        }
        return res.data;

    } catch (err) {
        //handle get key 404 manually
        if (url == '/device/enrollkey' && type == 'GET' && err.response.status === 404) {
            return "404"
        }
        if (err.response.data.message === 'There is no accessible locations for the current user') {
            return []
        }
        if(APILOGGING){
            console.log(`DEBUG RESPONSE: ${options.method} ${options.url}`, err);
        }
        if (!err.response) {
            throw err;
        }

        if (err.response.status === 401) {
            const message = (err.response.data.message || '').trim();
            if (
                [
                    'Not a valid token',
                    'Not a valid JWT token',
                    'Invalid token',
                    'Invalid Token.',
                ].includes(message)
            ) {
                logout();

                const tokenExpTimestamp = Number.parseInt(tokenExp);

                if (tokenExpTimestamp) {
                    const diffMinutes = moment( Number.parseInt(tokenExp) * 1000 ).diff(moment(), 'm');
                    if (diffMinutes < 0) {
                        if (!autoExit) {
                            autoExit = true;
                            messageAlert.error( `Your session has timed out after 15 minutes of inactivity.  Please login again to continue using AMiE.` );
                            throw new Error(`Your session has timed out after 15 minutes of inactivity.  Please login again to continue using AMiE.`);
                        }
                    }
                }

                if (!autoExit) {
                    autoExit = true;
                    messageAlert.error( `Your session has timed out.  Please login again to continue using AMiE.` );
                    throw new Error(`Your session has timed out.  Please login again to continue using AMiE.`);
                }
                throw new Error('Authentication Error');
            }
        }

        throw new Error(`${err.response.status} ${err.response.data.message}`);
    }
};

export const get = (url, data, config) => {
    return customAxios(url, 'GET', data, config);
};
export const post = (url, data, ajaxConfig) => {
    return customAxios(url, 'POST', data, ajaxConfig);
};
export const put = (url, data) => {
    return customAxios(url, 'PUT', data);
};
export const del = (url, data) => {
    return customAxios(url, 'DELETE', data);
};
export const formdata = (url, data) => {
    return customAxios(url, 'FORMDATA', data);
};
export const download = async (url, filename, extension = 'pdf') => {
    const data = await customAxios(url, 'DOWNLOAD');
    let type = '';
    switch (extension) {
        case 'pdf':
            type = 'application/pdf';
            break;
        default:
            type = 'application/pdf';
            break;
    }
    console.log(type);
    var blob = new Blob([data], {
        type: type + ';charset=utf-8',
    });
    saveAs(blob, `${filename || 'file'}.${extension}`);
};

//Process according to corresponding functions

const reqQueue = {};

export const table = async (url, filter, sort, pagination, option = {}) => {
    if (reqQueue[url] && !option.exportToCSV) {
        //Cancel the previous request
        reqQueue[url].cancel();
    }

    const source = axios.CancelToken.source();
    if (!option.exportToCSV) {
        reqQueue[url] = source;
    }

    const { pageSize, current } = pagination || {
        pageSize: 50,
        current: 1,
    };
    sort = sort || {};
    const params = {
        start: (current - 1) * pageSize,
        length: pageSize,
        ...filter,
        sortField: sort.field || '',
        sortOrder: sort.order
            ? sort.order === 'descend'
                ? 'DESC'
                : 'ASC'
            : null,
        ...option,
    };
    const data = await get(url, params, {
        cancelToken: source.token,
    });

    if (option.exportToCSV) {
        // console.log(data);
        var blob = new Blob([data ? `\uFEFF` + data : ''], {
            type: 'text/csv;charset=utf-8',
        });
        saveAs(blob, `${option.fileName || 'file'}.csv`);
    }
    return data;
};

// https://github.com/axios/axios
//axios response
//`{
//    // `data` is the response that was provided by the server
//    data: { },

//    // `status` is the HTTP status code from the server response
//    status: 200,

//    // `statusText` is the HTTP status message from the server response
//    statusText: 'OK',

//            // `headers` the headers that the server responded with
//            // All header names are lower cased
//    headers: { },

//    // `config` is the config that was provided to `axios` for the request
//    config: { },

//    // `request` is the request that generated this response
//    // It is the last ClientRequest instance in node.js (in redirects)
//    // and an XMLHttpRequest instance in the browser
//    request: { }
//}
//`
