import React, { PureComponent } from 'react';
import { FormContext } from '../context';
// import {Form} from "antd";

export default class FormItemsContainer extends PureComponent {
    static contextType = FormContext;
    render() {
        const { label, required, className, condition } = this.props;
        const form = this.context.form.current;
        const values = form.getFieldsValue();
        if (condition) {
            if (!condition(values)) {
                return <></>;
            }
        }
        return (<div className={"" + className + (required ? " required" : "")} >
            {!!label && <label className="ant-form-item-label">{label}</label>}
            <div className="">{this.props.children}</div>
        </div>)
    }

}


