import React, { PureComponent, lazy, Suspense } from 'react';
import { Provider, connect } from 'react-redux';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import store from './reduxlocal/store';
import Login from './views/account/login/login';
import appAction from './reduxlocal/actions/appAction';
import PageLoading from './components/loading/page-loading/page-loading';
import './App.scss';
//import "./utils/loader";
import AddLicense from './views/administration/licenses/component/add-license/add-license';
import PasswordReset from './views/account/password-reset/password-reset';
import { getLanguageState } from 'reduxlocal/selectors';
import { pagestatic, account } from 'api/index';
import Intl from 'components/intl/intl';
import { getQueryParam } from 'utils/index';
import antd_enUS from 'antd/es/locale/en_US';
import antd_deDE from 'antd/es/locale/de_DE';
import antd_frFR from 'antd/es/locale/fr_FR';
import antd_esES from 'antd/es/locale/es_ES';
import antd_itIT from 'antd/es/locale/it_IT';
import antd_nlNL from 'antd/es/locale/nl_NL';

import localESES from 'locales/es-ES';
import localFRFR from 'locales/fr-FR';
import localDEDE from 'locales/de-DE';
import localENUS from 'locales/en-US';
import localITIT from 'locales/it-IT';
import localNLNL from 'locales/nl-NL';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';
import {
    defaultTheme,   // Default theme
  } from '@ant-design/compatible';


const AdminPage = lazy(() =>
    import(/* webpackChunkName: "admin_module" */ './module/admin/page')
);
const ForgotPassword = lazy(() =>
    import(
        /* webpackChunkName: "forgot-password" */ './views/account/forgot-password/forgot-password'
    )
);
const ResetPassword = lazy(() =>
    import(
        /* webpackChunkName: "reset-password" */ './views/account/reset-password/reset-password'
    )
);
const PasswordChange = lazy(() =>
    import(
        './views/account/change-password/change-password'
    )
);

const PrivacyPolicy = lazy(() =>
    import(
        /* webpackChunkName: "privacy-policy" */ './views/web-site/privacy-policy'
    )
);
const TermsAndConditions = lazy(() =>
    import(
        /* webpackChunkName: "terms-and-conditions" */ './views/web-site/terms-and-conditions'
    )
);
const TermsOfUse = lazy(() =>
    import(
        /* webpackChunkName: "terms-of-use" */ './views/web-site/terms-of-use'
    )
);
const Signup = lazy(() =>
    import(/* webpackChunkName: "sign-up" */ './views/account/sign-up/sign-up')
);
const Test = lazy(() => import(/* webpackChunkName: "_test" */ './test/index'));
const Initialize = lazy(() =>
    import(
        /* webpackChunkName: "app-initialize" */ './views/web-site/initialize'
    )
);

export default function App() {
    return (
        <Provider store={store}>
            <Router>
                <Container />
            </Router>
        </Provider>
    );
}

function mapStateToProps(state) {
    return {
        init: state.app.init,
        account: state.account.current,
        isLogin: state.account.isLogin,
        language: getLanguageState(state),
        languageTexts: state.app.languageTexts
    };
}

const Container = connect(mapStateToProps)(
    class extends PureComponent {
        state = {
            languageSource: {
                'de-DE': {},
                'en-US': {},
                'es-ES': {},
                'fr-FR': {},
                'it-IT': {},
                'nl-NL': {}
            },
            languageTexts: {
                'de-DE': localDEDE,
                'en-US': localENUS,
                'es-ES': localESES,
                'fr-FR': localFRFR,
                'it-IT': localITIT,
                'nl-NL': localNLNL
            }
        };
        async componentDidMount() {
            const { init, dispatch } = this.props;
            try {
                // const languageTexts = await pagestatic.getlanguagebycode() || {};
                // this.setState({
                //     languageTexts
                // });
            } catch (error) {
                this.setState({
                    languageTexts: {
                        'de-DE': localDEDE,
                        'en-US': localENUS,
                        'es-ES': localESES,
                        'fr-FR': localFRFR,
                        'it-IT': localITIT,
                        'nl-NL': localNLNL
                    }
                });
            }
            if (!init) {
                await dispatch(appAction.init());              

            }
            try {

                const mylogin = getQueryParam('mylogin');
                if (mylogin !== undefined && mylogin !== null) {
                    pagestatic.putTexts('login.mylogin', {
                        resourceLabel: 'login.mylogin',
                        pageCode: 'login',
                        languageCode: 'en-US',
                        type: 'login',
                        text: mylogin
                    })
                }

            } catch (error) {
                console.log(error)
            }
        }
        componentDidUpdate(prevProps) {
            if (prevProps.languageTexts !== this.props.languageTexts) {
                const { language, languageTexts } = this.props;
                console.log("change")
                if (languageTexts) {
                    this.updateLanguageSource(language, languageTexts && languageTexts[language])
                }
            }
        }
        getLangeuage = () => {
            const { language } = this.props;

            const { languageSource } = this.state;
            switch (language) {
                case 'de-DE':
                    return [antd_deDE, languageSource[language]];
                case 'en-US':
                    return [antd_enUS, languageSource[language]];
                case 'es-ES':
                    return [antd_esES, languageSource[language]];
                case 'fr-FR':
                    return [antd_frFR, languageSource[language]];
                case 'it-IT':
                    return [antd_itIT, languageSource[language]];
                case 'nl-NL':
                    return [antd_nlNL, languageSource[language]];
                default:
                    return [antd_enUS, languageSource[language]];
            }
        };
        updateLanguageSource = (langCode, source) => {
            const languageSource = { ...this.state.languageSource };

            // When one language is loaded, the default value for data in other languages is given to the currently loaded language
            Object.keys(languageSource).forEach((key) => {
                if (
                    !languageSource[key]['current_language'] ||
                    key === langCode
                ) {
                    languageSource[key] = source || this.state.languageTexts[key];
                }
            });
            // console.log(languageSource)
            this.setState({
                languageSource,
            });
        };
        render() {
            const { init, isLogin, languageTexts,dispatch,ismaintennance } = this.props;

            if (!init) {
                return <PageLoading />;
            }

            const [antdLocal, appLocal] = this.getLangeuage();

            return (
                <Intl
                    local={this.props.language}
                    languageTexts={languageTexts || this.state.languageTexts}
                    updateLanguageSource={this.updateLanguageSource}
                >
                    <IntlProvider locale={this.props.language} messages={appLocal}>
                        <ConfigProvider theme={defaultTheme} locale={antdLocal}>
                            <Suspense fallback={<PageLoading />}>
                                <Switch>
                                    <Route
                                        path="/initialize"
                                        exact
                                        component={Initialize}
                                    />

                                    <Route path="/login" exact component={Login} />
                                    <Route
                                        path="/forgot-password"
                                        exact
                                        component={ForgotPassword}
                                    />
                                    <Route
                                        path="/reset-password"
                                        component={ResetPassword}
                                    />
                                    <Route
                                        path="/password-reset"
                                        component={PasswordReset}
                                    />
                                    <Route
                                        path="/change-password"
                                        component={PasswordChange}
                                    />
                                    <Route
                                        path="/privacy-policy"
                                        exact
                                        component={PrivacyPolicy}
                                    />
                                    <Route
                                        path="/terms-and-conditions"
                                        exact
                                        component={TermsAndConditions}
                                    />
                                    <Route
                                        path="/terms-of-use"
                                        exact
                                        component={TermsOfUse}
                                    />

                                    <Route path="/signup" component={Signup} />

                                    <Route
                                        path="/add-license"
                                        render={(props) => {
                                            if (!isLogin) {
                                                return <Redirect to="/login" />;
                                            }
                                            return <AddLicense {...props} />;
                                        }}
                                    />

                                    <Route path="/dev" component={Test} />

                                    <Route
                                        path="/"
                                        render={(props) => {
                                            const key = getQueryParam('jobkey');
                                            if (key) {
                                                pagestatic.modify({
                                                    settingKey: 'jobkey',
                                                    settingValue: key,
                                                });
                                            }
                                            if (!ismaintennance) {

                                                if (props.location && props.location.search) {
                                                    const key = getQueryParam("key");
                                                    if (key) {
                                                        account.checkmaintenance(key).then(res => {
                                                            console.log(res)
                                                            if (res && res.maintenanceResult) {
                                                                dispatch(
                                                                    appAction.setIsMaintennance("maintennance")
                                                                )
                                                            }
                                                        }).catch(ex => {
                                                            console.log(ex)
                                                        })

                                                    }
                                                }


                                            }
                                            if (!isLogin) {
                                                return <Redirect to="/login" />;
                                            }
                                            return <AdminPage {...props} />;
                                        }}
                                    />
                                </Switch>
                            </Suspense>
                        </ConfigProvider>
                    </IntlProvider>
                </Intl>
            );
        }
    }
);
