import cookie from '../../utils/cookie';
import { AppConstants, AccountConstants } from '../../constants/constants';
import { refresh, refreshAccount } from './accountAction';
import commomAction from './commomAction';
import {
    app,
    account,
    adminUsers,
    spectralinkAdmin,
    alertThresholds,
    pagestatic
} from '../../api';

import { tableHoc } from 'utils/table';

function init() {
    return async (dispatch) => {
        if (cookie.get(AppConstants.AUTH)) {
            await dispatch(refresh());

            try {
                dispatch(loadCustomers());
                //dispatch(await loadSites());
            } catch (e) {
                console.log(e)
             }
        }

        const sites = cookie.get(AppConstants.CURRENT_CUSTOMER);
        const organization = cookie.get(AppConstants.CURRENT_ORGANIZATION);
        const location = cookie.get(AppConstants.CURRENT_LOCATION);
        const accountId = cookie.get(AppConstants.CURRENT_ACCOUNT);
        const current_language = cookie.get(AppConstants.CURRENT_LANGUAGE);
        //api verify auth
        dispatch({
            type: AppConstants.INIT_APP,
        });

        dispatch({
            type: AppConstants.CURRENT_CUSTOMER,
            data: sites,
        });
        dispatch({
            type: AppConstants.CURRENT_ORGANIZATION,
            payload: organization,
        });
        dispatch({
            type: AppConstants.CURRENT_LOCATION,
            payload: location,
        });
        dispatch({
            type: AppConstants.CURRENT_ACCOUNT,
            payload: accountId,
        });
        if (current_language) {
            dispatch({
                type: AppConstants.SET_APP_LANGUAGE,
                payload: current_language,
            });
        }
        await dispatch(setLanguageTexts());
    };
}

function setCurrentCustomer(id) {
    return (dispatch) => {
        cookie.set(AppConstants.CURRENT_CUSTOMER, id);
        dispatch({
            type: AppConstants.CURRENT_CUSTOMER,
            data: id,
        });
    };
}

function loading() {
    return (dispatch) =>
        dispatch({
            type: AppConstants.LOADING_START,
        });
}

function loaded() {
    return (dispatch) =>
        dispatch({
            type: AppConstants.LOADING_END,
        });
}

function setTitle(title) {
    return (dispatch) =>
        dispatch({
            type: AppConstants.SET_TITLE,
            data: title,
        });
}

function loadCustomers() {
    return async (dispatch) => {
        //const res = await app.customers({}, {}, {
        //    current: 1,
        //    pageSize: 999,
        //});
        const res = JSON.parse(
            `{"data":[{"id":"304be86a-4034-4c8b-9110-9389ac71e223","customerName":"Vanilla Solutions","email":"qwe@qwe.com","companyName":"Vanilla Solutions","country":"United States","industry":"Information Technology","province":"Boston","alertType":"Fleet","alertStatus":"Acknowledged","phone":"6548542695","customerStatus":"Enabled","createTime":"2019-09-21T11:05:50.000Z"},{"id":"304be86a-4034-4c8b-9110-9389ac71e29f","customerName":"Vanilla Solutions - B","email":"qwe@qwe.com","companyName":"Vanilla Solutions - B","country":"United States","industry":"Information Technology","province":"Lansing","alertType":"Fleet","alertStatus":"Acknowledged","phone":"6548542695","customerStatus":"Enabled","createTime":"2019-09-21T12:30:09.000Z"}],"recordsFiltered":0,"recordsTotal":2}`
        );

        dispatch({
            type: AppConstants.LOAD_CUSTOMERS,
            data: res.data,
        });
    };
}

function loadSites() {
    return async (dispatch) => {
        const res = await app.sites(
            {},
            {},
            {
                current: 1,
                pageSize: 999,
            }
        );
        dispatch({
            type: AppConstants.LOAD_SITES,
            data: res.data,
        });
    };
}
export const  setIsMaintennance=(value) =>{
    return (dispatch) =>
        dispatch({ type: AppConstants.SET_MAINTENNANCE, payload: value });
}

function setMenus(menus) {
    return (dispatch) => {
        dispatch({
            type: AppConstants.SET_MENUS,
            payload: menus,
        });
    };
}

function setCurrentActiveMenu(key) {
    return (dispatch) => {
        dispatch({
            type: AppConstants.SET_CURRENT_ACTIVE_MENU,
            payload: key,
        });
    };
}

function setCurrentPageInfo(title, currentActiveMenu) {
    return (dispatch) => {
        dispatch({
            type: AppConstants.SET_CURRENT_PAGE_INFO,
            payload: {
                title,
                currentActiveMenu,
            },
        });
    };
}

function setCurrentAccount(id) {
    return async (dispatch) => {
        dispatch({
            type: AppConstants.CURRENT_ACCOUNT,
            payload: null,
        });
        dispatch({
            type: AppConstants.CURRENT_ORGANIZATION,
            payload: '',
        });
        dispatch({
            type: AppConstants.CURRENT_LOCATION,
            payload: '',
        });
        cookie.del(AppConstants.CURRENT_ORGANIZATION);
        cookie.del(AppConstants.CURRENT_LOCATION);

        const newToken = await account.setCurrentAccount(id);
        // dispatch({ type: "CLEAR_DATA" });
        cookie.set(AppConstants.AUTH, newToken);
        cookie.set(AppConstants.CURRENT_ACCOUNT, id);

        await dispatch(refresh());

        dispatch({
            type: AppConstants.CURRENT_ACCOUNT,
            payload: id,
        });
        //clear

        // await dispatch(refresh());
        // dispatch(commomAction.getLocations());
        // dispatch(commomAction.getOrganizations());
        // dispatch(commomAction.getLocationOptions());
        // await dispatch(setCurrentLocation(""));
    };
}

function setCurrentOrganization(id) {
    return async (dispatch) => {
        const newToken = await account.setOrganization(id);
        // dispatch({ type: "CLEAR_DATA" });
        cookie.set(AppConstants.AUTH, newToken);
        cookie.set(AppConstants.CURRENT_ORGANIZATION, id);
        dispatch({
            type: AppConstants.CURRENT_ORGANIZATION,
            payload: id,
        });
       
        // dispatch(commomAction.getLocations());
        // dispatch(commomAction.getOrganizations());
        // dispatch(commomAction.getLocationOptions());

        await dispatch(setCurrentLocation(''));
        await dispatch(refreshAccount());
    };
}

function setUserTimezone(timezone, dateFormat, timeFormat) {
    return async (dispatch) => {
        const newToken = await adminUsers.updateUserTimezone(
            timezone,
            dateFormat,
            timeFormat
        );
        // dispatch({ type: "CLEAR_DATA" });
        cookie.set(AppConstants.AUTH, newToken);

        const current = await account.current();

        dispatch({
            type: AccountConstants.SIGN_IN,
            data: current,
        });
    };
}

function setCurrentLocation(id) {
    return async (dispatch) => {
        const newToken = await account.setLocation(id);
        dispatch({
            type: 'CLEAR_DATA',
        });
        cookie.set(AppConstants.AUTH, newToken);
        cookie.set(AppConstants.CURRENT_LOCATION, id);
        dispatch({
            type: AppConstants.CURRENT_LOCATION,
            payload: id,
        });

        await dispatch(initGlobalData());

        // dispatch(commomAction.getLocations());
        // dispatch(commomAction.getOrganizations());
        // dispatch(commomAction.getLocationOptions());
    };
}
function setAdminAccount(id) {
    return async (dispatch) => {
        dispatch({
            type: AppConstants.ADMIN_ACCOUNT,
            payload: '',
        });
        dispatch({
            type: AppConstants.ADMIN_ORGANIZATION,
            payload: '',
        });

        dispatch({
            type: AppConstants.ADMIN_ACCOUNT,
            payload: id,
        });

    };
}

function setAdminOrganization(id) {
    return async (dispatch) => {

        dispatch({
            type: AppConstants.ADMIN_ORGANIZATION,
            payload: id,
        });

    };
}

function initGlobalData() {
    return async (dispatch) => {
        const locationsPromise = dispatch(commomAction.getLocationOptions());

        dispatch(commomAction.getOrganizations());
        dispatch(commomAction.getRolegetOptions());
        dispatch(commomAction.getTimeZones());
        dispatch(commomAction.getCountryOptions());
        dispatch(commomAction.getSubdivisionOptions());

        await locationsPromise;
        dispatch(commomAction.getLocations());
        dispatch(commomAction.getSettingsList());
    };
}

export const getSpectralinkAdmin = () => {
    return async (dispatch) => {
        const data = await spectralinkAdmin.getSpectralinkAdmin();
        dispatch({
            type: AppConstants.GET_SPECTRALINK_ADMIN,
            payload: data,
        });
    };
};

export const updateSpectralinkAdminBranding = (payload) => {
    return async (dispatch) => {
        const data = await spectralinkAdmin.updateBranding(payload);
        dispatch({
            type: AccountConstants.UPDATE_ACCOUNT_BRANDING_DATA,
            payload: data,
        });
    };
};
export const updateSpectralinkAdminGlobalSetting = (payload) => {
    return async (dispatch) => {
        const data = await spectralinkAdmin.updateGlobalSettings(payload);
        dispatch({
            type: AppConstants.GET_SPECTRALINK_ADMIN,
            payload: data,
        });
    };
};
export const updateSpectralinkAdminEULA = (eula) => {
    return async (dispatch) => {
        await spectralinkAdmin.updateEULA(eula);
        dispatch({
            type: AppConstants.UPDATE_SPECTRALINK_EULA,
            payload: eula,
        });
    };
};

export const resetColorToDefault = (payload) => {
    return async (dispatch) => {
        const data = await spectralinkAdmin.resetColorToDefault(payload);
        dispatch({
            type: AccountConstants.UPDATE_ACCOUNT_BRANDING_DATA,
            payload: data,
        });
    };
};
export const resetToDefaultLogo = (payload) => {
    return async (dispatch) => {
        const data = await spectralinkAdmin.resetToDefaultLogo(payload);
        dispatch({
            type: AccountConstants.UPDATE_ACCOUNT_BRANDING_DATA,
            payload: data,
        });
    };
};
const loadAlertThresholdTable = tableHoc(async (...p) => {
    return {
        res: await alertThresholds.table(...p),
        actionType: AppConstants.LOAD_ALERT_THRESHOLD_TABLE,
    };
});

function setLanguageTexts() {
    return async (dispatch) => {
        const texts = await pagestatic.getlanguagebycode();
        dispatch({
            type: AppConstants.SET_APP_LANGUAGE_TEXTS,
            payload: texts,
        });
    };
}

function setLanguage(languageCode) {

    return async (dispatch) => {
        cookie.set(AppConstants.CURRENT_LANGUAGE, languageCode || '', 30);
        dispatch({
            type: AppConstants.SET_APP_LANGUAGE,
            payload: languageCode,
        });
    };
}
function setNotificationTimeRange(range) {
    return (dispatch) => {
        dispatch({
            type: AppConstants.SET_NOTIFICATION_TIME_RANGE,
            payload: range,
        });
    };
}


export default {
    init,
    setTitle,
    loadCustomers,
    loadSites,
    loading,
    loaded,
    setCurrentCustomer,
    setCurrentActiveMenu,
    setCurrentPageInfo,
    setCurrentOrganization,
    setCurrentLocation,
    initGlobalData,
    setMenus,
    setIsMaintennance,
    setUserTimezone,
    setCurrentAccount,
    setLanguage,
    setLanguageTexts,
    setAdminAccount,
    setAdminOrganization,
    getSpectralinkAdmin,
    updateSpectralinkAdminBranding,
    updateSpectralinkAdminGlobalSetting,
    updateSpectralinkAdminEULA,
    resetColorToDefault,
    resetToDefaultLogo,

    loadAlertThresholdTable,
    setNotificationTimeRange,
};
