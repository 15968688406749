import React, { PureComponent } from 'react';
import {Form} from "antd";

//import '@ant-design/compatible/assets/index.css';


import { Radio } from 'antd';
import { FormContext } from '../context';

export default class FormRadio extends PureComponent {
    static contextType = FormContext;

    render() {
        const {
            options,
            value,
            name,
            rules,
            label,
            onChange,
            className,
            colon,
            disabled,
            layout,
            render,
            condition
        } = this.props;
        const form = this.context.form.current;
        if (condition) {
            if (!condition(form.getFieldsValue())) {
                return <></>;
            }
        }
        return (
            <Form.Item
                label={label}
                className={`form-radio ${layout || ''}`}
                colon={colon === false ? false : true}
                initialValue={value}
                rules={rules}
                name={name}
            >
                    <Radio.Group
                        label={label}
                        name={name}
                        disabled={disabled}
                        className={className || ''}
                        onChange={onChange}
                    >
                        {options && (
                            <>
                                {render
                                    ? render(options)
                                    : options.map((option) => (
                                        <Radio
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.text}
                                        </Radio>
                                    ))}
                            </>
                        )}
                    </Radio.Group>
            </Form.Item>
        );
    }
}
