import cookie from '../../utils/cookie';
import { account } from '../../api';
//import appAction from "./appAction";
// import commomAction from './commomAction';
import appAction from './appAction';

import {
    AccountConstants,
    AppConstants,
    roleTypeConstants,
    globalConstants,
} from '../../constants/constants';
import { tableHoc } from 'utils/table';
import { filterMenu } from 'utils';
import cloneDeep from 'clone-deep';

export function signIn(param) {
    return async (dispatch) => {
        console.log("param.username")
        console.log(param.username)
        const res = await account.signIn(param.username, param.password, param.language, param.gatewayId);

        if (res.success) {
            cookie.set(AppConstants.AUTH, res.token);
            cookie.set(AppConstants.REFRESH_TOKEN, res.refreshToken);
            cookie.set(AppConstants.TOKEN_EXP, res.exp);
        }

        const { authorizationCode } = res
        if (authorizationCode) return { authorizationCode }

        // clear pre cookie
        cookie.del(AppConstants.CURRENT_ORGANIZATION);
        cookie.del(AppConstants.CURRENT_LOCATION);
        cookie.del(AppConstants.CURRENT_ACCOUNT);

        dispatch({
            type: AppConstants.CURRENT_ORGANIZATION,
        });
        dispatch({
            type: AppConstants.CURRENT_LOCATION,
        });
        dispatch({
            type: AppConstants.CURRENT_ACCOUNT,
        });

        dispatch({
            type: AppConstants.INIT_DATA,
        });

        const current = await account.current();
       

        cookie.set(AppConstants.CURRENT_ACCOUNT, current.accountId);
        dispatch({
            type: AppConstants.CURRENT_ACCOUNT,
            payload: current.accountId,
        });
        dispatch({
            type: AccountConstants.SIGN_IN,
            data: current,
        });

        if (
            current &&
            current.organizationIds.length === 1 &&
            current.roleType !== roleTypeConstants.SPECTRALINK_USER
        ) {
            await dispatch(
                appAction.setCurrentOrganization(current.organizationIds[0])
            );
        }
        //dispatch(await appAction.loadCustomers());
        //dispatch(await appAction.loadSites());
        // dispatch(commomAction.getOrganizations());
        // dispatch(commomAction.getLocationOptions());
        await dispatch(appAction.initGlobalData());
        // await dispatch(commomAction.getSettingsList());
        await dispatch(appAction.getSpectralinkAdmin());
        // await Promise.all([
        //     dispatch(commomAction.getSettingsList()),
        //     dispatch(appAction.getSpectralinkAdmin()),
        // ]);

        return {
            lastLogin: res.lastLogin,
            token: res.token,
            isTemporaryPassword: current.isTemporaryPassword,
        };
    };
}

export function logout() {
    const current_language = cookie.get(AppConstants.CURRENT_LANGUAGE);
    cookie.del(AppConstants.AUTH);
    cookie.del(AppConstants.CURRENT_CUSTOMER);
    cookie.del(AppConstants.CURRENT_ORGANIZATION);
    cookie.del(AppConstants.CURRENT_LOCATION);
    cookie.del(AppConstants.REFRESH_TOKEN);
    cookie.del(AppConstants.CURRENT_ACCOUNT);
    cookie.del(AppConstants.TOKEN_EXP);
    return (dispatch) => {
        dispatch({
            type: AppConstants.CURRENT_CUSTOMER,
        });
        dispatch({
            type: AppConstants.CURRENT_ORGANIZATION,
        });
        dispatch({
            type: AppConstants.CURRENT_ACCOUNT,
        });
        dispatch({
            type: 'USER_LOGOUT',
        });
        dispatch({
            type: AccountConstants.LOGOUT,
        });
        dispatch({
            type: AppConstants.INIT_APP,
        });
        dispatch({
            type: AppConstants.SET_APP_LANGUAGE,
            payload: current_language,
        });
        document.title = AppConstants.TITLE;
    };
}

export function refresh(type) {
    return async (dispatch) => {
        try {
            const current = await account.current();
            if (!type) {
                if (
                    current &&
                    current.organizationIds.length === 1 &&
                    current.roleType !== roleTypeConstants.SPECTRALINK_USER
                ) {
                    await dispatch(
                        appAction.setCurrentOrganization(current.organizationIds[0])
                    );
                }
            }
            dispatch({
                type: AccountConstants.SIGN_IN,
                data: current,
            });

            let menus = cloneDeep(globalConstants.MENUS);
            menus = filterMenu(menus, current.permissions, current.roleType);
            dispatch(appAction.setMenus(menus));
            // dispatch(commomAction.getOrganizations());
            // dispatch(commomAction.getRolegetOptions());
            // dispatch(commomAction.getLocations());
            // dispatch(commomAction.getLocationOptions());

            // await Promise.all([
            //     dispatch(commomAction.getSettingsList()),
            //     dispatch(appAction.getSpectralinkAdmin()),
            // ]);
            // await dispatch(commomAction.getSettingsList());
            await dispatch(appAction.initGlobalData());

            await dispatch(appAction.getSpectralinkAdmin());
        } catch (e) {
            console.log(e);
            await dispatch(logout());
        }
    };
}
export function refreshAccount() {
    return async (dispatch) => {
        try {
            const current = await account.current();
            
            dispatch({
                type: AccountConstants.SIGN_IN,
                data: current,
            });

            let menus = cloneDeep(globalConstants.MENUS);
            menus = filterMenu(menus, current.permissions, current.roleType);
            
            dispatch(appAction.setMenus(menus));
            
        } catch (e) {
            console.log(e);
            await dispatch(logout());
        }
    };
}
export function getCurrentAccountInfo() {
    return async (dispatch) => {
        try {
            const current = await account.current();
            dispatch({
                type: AccountConstants.SIGN_IN,
                data: current,
            });
        } catch (e) {
            await dispatch(await logout());
        }
    };
}
export function updateCurrentAvatar(url) {
    return (dispatch) => {
        dispatch({
            type: AccountConstants.UPDATE_CURRENT_AVATAR,
            payload: url,
        });
    };
}
export const getPermissions = () => {
    return async (dispatch) => {
        const permissions = await account.role.getPermissions();
        dispatch({
            type: AccountConstants.GET_PERMISSIONS,
            payload: permissions,
        });
    };
};

export const getTableData = tableHoc(async (filter, sort, pagination) => {
    return {
        res: await account.table(filter, sort, pagination),
        actionType: AccountConstants.GET_ACCOUNTS_TABLE_DATA,
    };
});
