import React, { PureComponent } from 'react';
import { Modal } from 'antd';
import FormCntainer from './container';

class FormModal extends PureComponent {
    handleOk = () => {
        this.submitBtn.click();
    };

    handleCancel = () => {
        this.props.onCancel && this.props.onCancel();
        this.closeModal();
    };

    closeModal = () => {
        this.props.closeModal && this.props.closeModal();
    };

    onSubmit = async (values) => {
        if (this.props.disabled) {
            return false;
        }
        const result = await this.props.onOk(values);
        if (result !== false) {
            this.closeModal();
        }
    };
    getModalClassName = () => {
        const { footerAlign, className } = this.props;
        return footerAlign + ' ' + className;
    };
    render() {
        const {
            title,
            width,
            visible,
            children,
            className,
            destroyOnClose,
            loading,
            disabled,
            footer,
            onRef,
            okText,
            btnId
        } = this.props;
        return (
            <Modal
                centered
                title={title}
                width={width}
                open={visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                okText={okText || 'Confirm'}
                cancelText="Cancel"
                cancelButtonProps={{ id: `btn_cancel_${btnId || '_none'}` }}
                destroyOnClose={destroyOnClose}
                className={(disabled ? 'disbaled' : ' ') + this.getModalClassName()}
                footer={footer}
            >
                <FormCntainer
                    onSubmit={this.onSubmit}
                    className={className}
                    loading={loading}
                    onRef={onRef}
                >
                    {children}
                    <button
                        type="submit"
                        ref={(submitBtn) => (this.submitBtn = submitBtn)}
                        className="hide"
                    ></button>
                </FormCntainer>
            </Modal>
        );
    }
}

export default FormModal;
