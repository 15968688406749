import moment from 'moment';
import {
    roleTypeConstants,
    globalConstants,
    baseColors,
} from 'constants/constants';

export const deviceModelToFormat = (deviceModel) => {
    if (!deviceModel) {
        return '';
    }
    const sp = deviceModel.split(' ');

    if (sp.length < 2) {
        return deviceModel;
    }
    sp.pop();
    return sp.join(' ');
};
export const getPopupContainer = (triggerNode) => {
    if (window.$(triggerNode).parents('header.ant-layout-header').length > 0) {
        return document.body;
    }
    if (window.$(triggerNode).parents('#main').length > 0) {
        return document.getElementById('main') || document.body;
    }
    return document.body;
};
export const getBatteryStatus = (degradation_pct) => {
    if (degradation_pct >= 80) {
        return 'Good';
    } else if (degradation_pct >= 65 && degradation_pct < 80) {
        return 'Warning';
    } else {
        return 'Replace';
    }
};

export const getExportFileName = (name) => {
    return `${name} ${moment().format('DDMMYYYYHHmmss')}`;
};

export const getQueryParam = (key) => {
    let reg = new RegExp('(^|&)' + key + '=([^&]*)(&|$)', 'i');
    let r = window.location.search.substr(1).match(reg);
    if (r != null) {
        return unescape(r[2]);
    }
    return null;
};
export const getQueryParamByURL = (key, url) => {
    let reg = new RegExp('(^|&)' + key + '=([^&]*)(&|$)', 'i');
    let r = url.substr(1).match(reg);
    if (r != null) {
        return unescape(r[2]);
    }
    return null;
};

export const getGatewayHealth = (health, poor, fair_health, unknown = 0) => {
    health = Number.parseInt(health) || 0;
    poor = Number.parseInt(poor) || 0;
    fair_health = Number.parseInt(fair_health) || 0;
    unknown = Number.parseInt(unknown) || 0;

    const total = health + fair_health + poor + unknown;

    const totalCal = health * 30 + poor * 20 + fair_health * 10 + unknown * 0;

    const avg = totalCal / (total || 1);

    if (avg > 25) {
        return 'healthy';
    } else if (avg > 15) {
        return 'poor';
    } else if (avg > 0) {
        return 'fair';
    } else {
        return 'unknown';
    }
};
export const isCsvFile = (filename) => {
    if (!filename || !filename.includes('.')) {
        return false;
    }

    const extension = filename.split('.').pop();

    if (extension.toLowerCase() === 'csv') {
        return true;
    }
};
export function formatAPBssid(ap_bssid) {
    if (!ap_bssid) {
        return ap_bssid;
    }
    return ap_bssid.substr(0, ap_bssid.length - 1);
}
export function formatFloorNumber(floorNumber) {
    if (!floorNumber) {
        //includes 0
        return floorNumber;
    }
    return floorNumber < 0 ? `B${Math.abs(floorNumber)}` : floorNumber;
}
export const decodeAmiePackage = (amiePackage) => {
    const packages = [
        // {
        //     value: 'FPK9562164', text: 'AMIE Advanced for Versity (initial 1 year)',
        // },
        // {
        //     value: 'FPK9562174',
        //     text: 'AMIE Advanced for Versity (1 year renewal)',
        // },
        // {
        //     value: 'FPK9562165',
        //     text: 'AMIE Advanced for Versity (initial 3 years)',
        // },
        // {
        //     value: 'FPK9562175',
        //     text: 'AMIE Advanced for Versity (3 year renewal)',
        // },
        {
            value: 'FPK9562186',
            text: 'AMIE Advanced Upgrade (Essential to Advanced)',
        },
        { value: 'TPK9561164', text: 'AMiE Advanced 30-day Trial/Proof of Concept', },
        { value: 'FPK9561164', text: 'AMIE Essentials for Versity First Year - 1 User', },
        { value: 'FPK9561165', text: 'AMIE Essentials for Versity First 3 Years - 1 User', },
        { value: 'FPK9561166', text: 'AMIE Essentials for Versity First 5 Years - 1 User', },
        { value: 'FPK9561174', text: 'AMIE Essentials for Versity Renewal - 1 User', },
        { value: 'FPK9561175', text: 'AMIE Essentials for Versity 3 Years Renewal - 1 User', },
        { value: 'FPK9562164', text: 'AMIE Advanced for Versity First Year - 1 Device', },
        { value: 'FPK9562165', text: 'AMIE Advanced for Versity First Three Years - 1 Device', },
        { value: 'FPK9562166', text: 'AMIE Advanced for Versity (Five Years)', },
        { value: 'FPK9562174', text: 'AMIE Advanced for Versity Renewal - 1 Device', },
        { value: 'FPK9562175', text: 'AMIE Advanced for Versity Three Year Renewal - 1 Device', },
        { value: 'FPK9562176', text: 'AMIE Advanced for Versity (Five Year Renewal)', },
        { value: 'FPK8461161', text: 'AMIE Essentials for 8400 First Year - 1 Device', },
        { value: 'FPK8461163', text: 'AMIE Essentials for 8400 First 3 Years - 1 Device', },
        { value: 'FPK8461165', text: 'AMIE Essentials for 8400 First 5 Years - 1 Device', },
        { value: 'FPK8461171', text: 'AMIE Essentials for 8400 1 Year Renewal - 1 Device', },
        { value: 'FPK8461173', text: 'AMIE Essentials for 8400 3 Year Renewal - 1 Device', },
        { value: 'FPK8462161', text: 'AMIE Advanced for 8400 First Year - 1 Device', },
        { value: 'FPK8462163', text: 'AMIE Advanced for 8400 First 3 Years - 1 Device', },
        { value: 'FPK8462165', text: 'AMIE Advanced for 8400 First 5 Years - 1 Device', },
        { value: 'FPK8462171', text: 'AMIE Advanced for 8400 1 Year Renewal - 1 Device', },
        { value: 'FPK8462173', text: 'AMIE Advanced for 8400 3 Year Renewal - 1 Device', },
        { value: 'FPK8462175', text: 'AMIE Advanced for 8400 5 Year Renewal - 1 Device' },
    ];

    const packet = packages.find((f) => f.value === amiePackage);

    if (packet) {
        return packet.text;
    }

    return amiePackage;
};

function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {
        type: mime,
    });
}

function blobToFile(theBlob, fileName) {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
}

export function dataURLtoFile(dataurl, fileName) {
    const blob = dataURLtoBlob(dataurl);

    return blobToFile(blob, fileName);
}

export const filterMenu = (menus, permissions, roleType) => {
    menus = menus.filter(
        (f) =>
            f.type === 'group' ||
            permissions.includes(f.key) ||
            (f.key === 'accounts' &&
                (roleType === roleTypeConstants.SPECTRALINK_USER ||
                    roleType === roleTypeConstants.SERVICE_PROVIDER))
    );

    //If top is followed by bottom
    menus = menus.filter((f, i) => {
        if (f.type === 'group') {
            if (f.postion === 'top' && i < menus.length - 1) {
                if (menus[i + 1].postion === 'bottom') {
                    return false;
                }
            } else if (f.postion === 'bottom' && i > 0) {
                if (menus[i - 1].postion === 'top') {
                    return false;
                }
            }
        }
        return true;
    });

    return filterSubMenu(menus, permissions);
};

const filterSubMenu = (menus, permissions) => {
    menus.forEach((f) => {
        if (f.children) {
            f.children = f.children.filter((f) => permissions.includes(f.key));
        }
    });

    menus = menus.filter((f) => !f.children || f.children.length > 0);
    return menus;
};

export const delay = (second) => {
    return new Promise((resolve) => {
        setTimeout(resolve, second * 1000);
    });
};

export const formatDeviceModel = (model) => {
    if (!model) {
        return globalConstants.UNKOWN;
    }

    if (model.startsWith('Versity')) {
        model = model.replace('Versity', '');
    }
    if (model.startsWith('VC')) {
        model = model.replace('VC', '');
    }

    return model.trim();
};
export const getDeviceImageUrl = (model) => {
    const _model = formatDeviceModel(model);

    if (['9540', '9640'].includes(_model)) {
        return ['/images/9540_9640.png', 100];
    }
    if (['9553', '9653'].includes(_model)) {
        return ['/images/9553_9653.png', 100];
    }
    if (['9240'].includes(_model)) {
        return ['/images/9240.jpg', 50];
    }
    if (['9253'].includes(_model)) {
        return ['/images/9253.jpg', 50];
    }
    // if (['8440'].includes(_model)) {
    if (_model && _model.startsWith('84')) {
        return ['/images/8440.jpg', 50];
    }
    return ['/images/phone.png', 50];
};
export const formatBatteryModel = (
    model,
    deviceModel = '',
    batterySerial = ''
) => {
    if (!model) {
        return globalConstants.UNKOWN;
    }

    if (deviceModel && deviceModel.startsWith('84')) {
        if (batterySerial) {
            if (batterySerial.startsWith('AC')) {
                return '84 Series - Standard';
            }
            if (batterySerial.startsWith('AD')) {
                return '84 Series - Extended';
            }
        }
    }

    if (model.startsWith('Versity')) {
        model = model.replace('Versity', '').trim();

        model = model.substring(0, 2) + ' Series';
    }
    if (model.startsWith('VC')) {
        model = model.replace('VC', '').trim();
        model = model.substring(0, 2) + ' Series';
    }

    return model.trim();
};

export const formatBatteryModel2 = (model) => {
    model = formatBatteryModel(model);

    if (['95 Series', '96 Series'].includes(model)) {
        model = '95/96 Series';
    }
    return model.trim();
};

export function compareFunction(propertyName) {
    return function (src, tar) {
        var v1 = src[propertyName];
        var v2 = tar[propertyName];
        if (v1 > v2) {
            return 1;
        }
        if (v1 < v2) {
            return -1;
        }
        return 0;
    };
}

export const getGatewayHealthColor = (health) => {
    switch (health) {
        case 'POOR':
            return 'red';
        case 'FAIR':
            return 'yellow';
        case 'GOOD':
            return 'green';
        default:
            return 'red';
    }
};

export const performanceColorCondition = {
    callPerformance(performance) {
        if (performance.avg_ap_mos === undefined) {
            return baseColors.gray;
        } else if (performance.avg_ap_mos >= 4) {
            return baseColors.green;
        } else if (performance.avg_ap_mos >= 3.6) {
            return baseColors.yellow;
        } else {
            return baseColors.red;
        }
    },
    droppedCalls(performance) {
        if (performance.dropped_call_rate === undefined) {
            return baseColors.gray;
        } else if (performance.dropped_call_rate >= 5) {
            return baseColors.red;
        } else if (performance.dropped_call_rate >= 2) {
            return baseColors.yellow;
        } else {
            return baseColors.green;
        }
    },
    longHandoffs(longHandoff) {
        if (longHandoff.avg_handoff_time === undefined) {
            return baseColors.gray;
        } else if (longHandoff.avg_handoff_time >= 50) {
            return baseColors.red;
        } else if (longHandoff.avg_handoff_time >= 45) {
            return baseColors.yellow;
        } else {
            return baseColors.green;
        }
    },
    lowRSSI(lowRSSI) {
        if (lowRSSI.low_rssi_call_minute_percentage === undefined) {
            return baseColors.gray;
        } else if (lowRSSI.low_rssi_call_minute_percentage >= 10) {
            return baseColors.red;
        } else if (lowRSSI.low_rssi_call_minute_percentage >= 4) {
            return baseColors.yellow;
        } else {
            return baseColors.green;
        }
    },
    droppedORMissedPackets(packet) {
        if (
            packet.packets_droppeds_rate === undefined ||
            packet.packets_misseds_rate === undefined
        ) {
            return baseColors.gray;
        } else if (
            packet.packets_droppeds_rate >= 2 ||
            packet.packets_misseds_rate >= 2
        ) {
            return baseColors.red;
        } else if (
            packet.packets_droppeds_rate >= 1 ||
            packet.packets_misseds_rate >= 1
        ) {
            return baseColors.yellow;
        } else {
            return baseColors.green;
        }
    },
    getColor(v) {
        if (v === baseColors.gray) {
            return 'Gray';
        } else if (v === baseColors.green) {
            return 'Green';
        } else if (v === baseColors.yellow) {
            return 'Yellow';
        } else {
            return 'Red';
        }
    },
    getGrade(v) {
        if (v === baseColors.gray) {
            return 'Unknow';
        } else if (v === baseColors.green) {
            return 'Good';
        } else if (v === baseColors.yellow) {
            return 'Fair';
        } else {
            return 'Poor';
        }
    }
};

export const addColorPropToPerformance = (data, condition) => {
    if (!data || !condition) {
        return data;
    }
    data.forEach((item) => {
        try {
            item.color = condition(item);
            // item.ap_ssid = 'VoIPWNET';
        } catch (e) {
            console.log(e)
         }
    });
    return data;
};
export const getVersityOsName = (os, device_model, device_sw_revision) => {
    if (!device_model) {
        return os || globalConstants.UNKNOWN;
    }
    if (device_model && device_model.startsWith('84')) {
        if (device_sw_revision) {
            const v = device_sw_revision.split('.');
            if (v.length === 4) {
                return v[0] + '.' + v[1];
            }
            // const v = device_sw_revision.split('.')[3];
            // if (v) {
            //     return device_sw_revision.replace(new RegExp(`.${v}$`), '');
            // }
        }
    }
    return os || globalConstants.UNKNOWN;
};
export const getVersityVersion = (
    device_sw_revision,
    defaultValue = 'Unknown',
    // device_model = ''
) => {
    if (!device_sw_revision) {
        return defaultValue || '';
    }
    // if (device_model && device_model.startsWith('84')) {
    //     return device_sw_revision.split('.')[3] || device_sw_revision;
    // }
    try {
        return device_sw_revision
            .match(/Versity:(\S*):/)[1]
            .match(/\/(\S*)/)[1];
    } catch (e) {
        return device_sw_revision;
    }
};
export const is84DeviceModel = (model) => {
    if (!model) {
        return false
    }
    return model.startsWith('84')
}
export const unique = (arr) => {
    var res = [];
    var obj = {};
    for (var i = 0; i < arr.length; i++) {
        if (!obj[arr[i]]) {
            obj[arr[i]] = 1;
            res.push(arr[i]);
        }
    }
    return res;
}